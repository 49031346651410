import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Box, TextField, Button } from "@material-ui/core";
import { UserContext } from "../UserContext";
import ParaObject from "para-client-js/lib/ParaObject";
import { VocoUpload } from "react-voco-upload/dist/components";
import { getApiAddress } from "react-voco-upload/src/shared/ioc/iocModules";
import { request, logger } from "react-voco-upload/src/shared/utils";
import "../common.css";
import config from "../config";
import { withRouter } from "react-router";

// import { file } from '@babel/types'

const allowedFormats = [".zip"];
const schemeId = config.schemeId;
const pathtolocation = "name,version,threeRadical,description,dependencies";
let context;

const setPackageVersion = (pkg, pkgUrl) => {
  var newPackageVersion = new ParaObject(pkg.name + "-" + pkg.version);
  newPackageVersion.type = "Version";
  newPackageVersion.setParentid(pkg.name);
  newPackageVersion.package = {
    name: pkg.name,
    version: pkg.version,
    description: pkg.description,
    dependencies: pkg.dependencies,
  };
  newPackageVersion.packageUrl = config.apiAddress + pkgUrl;
  newPackageVersion.threeRadical = {
    name: (pkg.threeRadical || {}).name || "test name",
    icon: (pkg.threeRadical || {}).icon || "test icon",
    ...pkg.threeRadical,
  };
  
  const keysToDelete = ['properties', 'schemeProperties', 'schemeActions', 'schemeFiles', 'lambda', 'outcomes']
  keysToDelete.forEach(key => { 
    if (newPackageVersion.threeRadical.hasOwnProperty(key)) {
      console.log(`${key} found in threeRadical removing it before updating database....`)
      delete newPackageVersion.threeRadical[key]
    }
  })
  
  return context.documentStore.create(newPackageVersion).then(
    function (pkgVersionObject) {
      console.log(
        "create newPackageVersion - pkgVersionObject=",
        pkgVersionObject.package.name
      );
      return true
    },
    function (err) {
      console.warn(err, err.stack);
    }
  );
};

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "upload",
      package: {},
      pkgUrl: "",
    };
  }

  static contextType = UserContext;

  uploadDone = (result) => {
    var array = result.filename.split("/");
    var fileId = array[array.length - 1].split(".zip")[0];
    var requestUrl = this.getPackageDetailUrl(fileId);

    request(requestUrl, { method: "GET" })
      .then((res) => {
        this.showPackgeDetails(JSON.parse(res), result.filename);
      })
      .catch((err) => {
        logger.error("get package detail ERROR", err);
      });
  };

  getPackageDetailUrl = (id) => {
    const apiPort = 3001;
    const apiPath =
      "external-package/" +
      schemeId +
      "/" +
      id +
      "/package/" +
      pathtolocation +
      "?delimiter=,";

    return getApiAddress({
      port: apiPort,
      path: apiPath,
    });
  };

  showPackgeDetails = (pkg, pkgUrl) => {
    this.setState({
      page: "packageDetail",
      package: pkg || {},
      pkgUrl: pkgUrl || "",
    });
  };

  setPackage = () => {
    var pkg = this.state.package;
    var pkgUrl = this.state.pkgUrl;
    if (!pkg.version) {
      alert("You should upload a package with a version");
      return;
    }

    context.documentStore.findById(pkg.name).then(function (foundPkg) {
      console.log("foundPkg=", foundPkg);
      if (
        foundPkg &&
        foundPkg[0] &&
        foundPkg[0].package.version == pkg.version
      ) {
        //foundPkg is null if not found
        alert("You should upload a package with higher version");
        return;
      }

      var newPackage = new ParaObject(pkg.name);
      newPackage.type = "Package";
      newPackage.package = {
        description: pkg.description || "test desc",
        version: pkg.version,
      };
      newPackage.threeRadical = {
        name: (pkg.threeRadical || {}).name || "test name",
        icon: (pkg.threeRadical || {}).icon || "test icon",
        ...pkg.threeRadical,
      }
      
      const keysToDelete = ['properties', 'schemeProperties', 'schemeActions', 'schemeFiles', 'lambda', 'outcomes']
      keysToDelete.forEach(key => { 
        if (newPackage.threeRadical.hasOwnProperty(key)) {
          console.log(`${key} found in threeRadical removing it before updating database....`)
          delete newPackage.threeRadical[key]
        }
      })
      
      context.documentStore.create(newPackage).then(
        function (pkgObject) {
          console.log("create newPackage - pkgObject=", pkgObject);
          setPackageVersion(pkg, pkgUrl).then(() => {
            window.location.href = "/#/packagedetails/" + pkgObject.id;
          });
        },
        function (err) {
          console.warn(err, err.stack);
        }
      );
    });
  };

  reUpload = () => {
    this.setState({ page: "upload" });
    console.log("00 mark: this.state.package=", this.state.package);
  };

  onPackageFieldChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const pkg = this.state.package;

    this.setState({ package: { ...pkg, [field]: value } });
  };

  render() {
    window.apiAddress = config.apiAddress;
    // let me = this.context.me
    let buttonAttrs = {
      variant: "contained",
      color: "secondary",
    };
    context = this.context;

    //test: sample search
    // context.documentStore.findQuery("Package").then(function(foundPkgVersion){
    //     console.log("00 mark: found Packages=", foundPkgVersion)
    // })
    // context.documentStore.findQuery("Version").then(function(foundPkgVersion){
    //     console.log("00 mark: found PackageVersions=", foundPkgVersion)
    // })

    let content;
    if (this.state.page == "upload") {
      content = (
        <Container maxWidth="sm">
          <Box my={4}>
            <Typography variant="h4" component="h4">
              Upload
            </Typography>
            <Typography variant="subtitle1" component="div">
              Upload a new package or version
            </Typography>
            {/* <Typography component="p">
                            {me ? "User has logged in: " + me.email : "Not Logged In" }
                        </Typography> */}
          </Box>

          <VocoUpload
            format={allowedFormats}
            schemeId={schemeId}
            caption="Upload"
            uploaded={this.uploadDone}
            buttonAttrs={buttonAttrs}
          />
        </Container>
      );
    } else if (this.state.page == "packageDetail") {
      content = (
        <Container>
          <Typography variant="h4" component="h4" className="section center">
            New Version
          </Typography>
          <div className="section">
            <div className="cm-col-100 cm-row">
              <TextField
                label="NAME"
                variant="outlined"
                className="pkg-field"
                value={(this.state.package || {}).name}
                name="name"
                onChange={this.onPackageFieldChange}
              />
            </div>
            <div className="cm-col-100 cm-row">
              <TextField
                label="THREERADICAL NAME"
                variant="outlined"
                className="pkg-field"
                value={(this.state.package.threeRadical || {}).name || ""}
                name="threeRadical"
                onChange={(e) => {
                  const pkg = this.state.package;
                  const threeRadical = pkg.threeRadical || {};

                  this.setState({
                    package: {
                      ...pkg,
                      threeRadical: { ...threeRadical, name: e.target.value },
                    },
                  });
                }}
              />
            </div>
            <div className="cm-col-100 cm-row">
              <TextField
                label="DESCRIPTION"
                variant="outlined"
                className="pkg-field"
                value={(this.state.package || {}).description}
                name="description"
                onChange={this.onPackageFieldChange}
              />
            </div>
            <div className="cm-col-100 cm-row">
              <TextField
                label="VERSION"
                variant="outlined"
                className="pkg-field"
                value={(this.state.package || {}).version}
                name="version"
                onChange={this.onPackageFieldChange}
                disabled //Package version needs to be updated through package.json or it will create issues with install processes on other services like VSP
              />
            </div>
            <div className="cm-col-100 cm-row">
              <div className="cm-col">
                <Button
                  variant="contained"
                  component="span"
                  color="secondary"
                  onClick={this.setPackage}
                  className="cm-btn"
                >
                  Save
                </Button>
              </div>
              <div className="cm-col">
                <Button
                  variant="contained"
                  component="span"
                  color="secondary"
                  onClick={this.reUpload}
                  className="cm-btn"
                >
                  Reupload
                </Button>
              </div>
            </div>
          </div>
        </Container>
      );
    }
    return content;
  }
}

export default withRouter(Upload);
