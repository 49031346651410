import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {Redirect as RouterRedirect} from "react-router-dom";
import {UserContext} from "../UserContext"
import PackageList from "./PackageList";

export default class Index extends Component {
    static contextType = UserContext

    render() {

        if (!this.context.me) {
            return (<RouterRedirect to="/Login" />)
        }

        return (
            <Container>
                <Box my={4}>
                     <PackageList />
                </Box>
            </Container>
        )
    }
}
