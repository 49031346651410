import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { UserContext } from "../../UserContext"
import VocoInterface from "app-context/voco-interface"
import { getBrandCodes } from "../../lib/brand"

import CircularProgress from "@material-ui/core/CircularProgress"
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import _ from "lodash"

class BrandComplete extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);

        this.state = {
            brand : false,
            environment : false,
            voco : false,
            salesDemo : false,
            live : false,
            uat : false,
            passed : false,
            errors : {
                healthy : true,
                privateAccess : true,
                newScheme : true
            }
        }
    }

    async loadBrand(brandId) {
        const documentStore = this.context.documentStore
        const brand = await documentStore.read("Brand", brandId)
        const environment = await documentStore.read("Environment", brand.environment)

        let deploymentInfo = environment.environment.deploymentInfo

        Object.keys(deploymentInfo.services).forEach(function (service) {
            if (!deploymentInfo.services[service].secret && typeof deploymentInfo.services[service] == "object") {
                deploymentInfo.services[service].secret = deploymentInfo.services.secret
            }
        })

        this.voco = new VocoInterface(deploymentInfo)

        this.setState({
            brand : brand,
            environment : environment
        })

        return brand
    }

    async testBrand(brand) {
        if (!this.voco) {
            return
        }

        let voco = this.voco
        let errors = {
            healthy : true,
            privateAccess : true,
            newScheme : true
        }

        let passed = true || 4
        this.setState({ errors : errors, passed : false })

        try {

            let health = await voco.gtp.get("/debug/health")
            errors.healthy = health.ok ? false : JSON.stringify(health)

            if (passed && !health.ok) {
                passed = false
            }

            this.setState({ errors : errors })

            let privateHealth = await voco.gtp.get("/private/debug/health")
            errors.privateAccess = privateHealth.ok ? false : JSON.stringify(privateHealth)

            if (passed && !privateHealth.ok) {
                passed = false
            }

            this.setState({ errors : errors })

            let brandCodes = getBrandCodes(this.state.brand.name)

            let scheme = await voco.gtp.post("/private/scheme/fromName", { appNames : [brandCodes.brandUrlToken] })

            if (!scheme[brandCodes.urlToken]) {
                errors.newScheme = false
            } else {
                errors.newScheme = brandCodes.urlToken + " already exists."

                if (passed) {
                    passed = false
                }
            }

        } catch (e) {
            console.warn(e, e.stack)
            this.setState({ errors : errors, error : true, errorMessage : e.message})
            passed = false
        }

        this.setState({ passed : passed })
    }

    async createBrand(brand) {
        const documentStore = this.context.documentStore
        const voco = this.voco
        let brandCodes = getBrandCodes(brand.name)
        let schemeId

        try {
            if(brand.origins && Array.isArray(brand.origins) && brand.origins.length) {
                brandCodes.downloaderModuleConfig = {main: {cors: {origins: brand.origins}}};
            }
            await voco.gtp.post("/private/add-scheme", brandCodes)

            const schemeResults = await voco.gtp.post("/private/scheme/fromName", { appNames : [brandCodes.brandUrlToken] })

            const schemeSettings = schemeResults[brandCodes.brandUrlToken]

            if (!schemeSettings) {
                throw new Error("Unable to retrieve scheme settings")
            }

            schemeId = _.get(schemeSettings, "webapp.globals.schemeId")

            if (!schemeId) {
                throw new Error("No Scheme Id")
            }


        } catch (e) {
            console.warn(e, e.stack)
            return this.setState({error : true, errorMessage : e.message})
        }

        let pkg, pkgVersion, pkgUrl, pkgHash

        for (pkg of brand.packages) {
            pkg = await documentStore.read("Package", pkg.id)
            pkgVersion = await documentStore.read("Version", pkg.id + "-" + pkg.package.version)
            pkgUrl = pkgVersion.packageUrl

            pkgHash = pkgUrl.split("/").pop().replace(/\.zip$/,"")
            await voco.gtp.post(
                "/private/external-package/" + schemeId + "/" + pkgHash + "/processUpload/name,main,version",
                {
                    "pathToProperties" : "schemeProperties",
                    "delimiter" : ",",
                    "packageUrl" : pkgUrl,
                    isRemotePackage: true
                }
            )
        }
        
        brand.state = "Completed"
        await documentStore.update(brand)
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.loadBrand(id)
    }

    render() {
        if (!this.state.brand) {
            return <CircularProgress className="circular" />;
        }

        return <Container>
            <Box>
                <Typography variant="h1">{this.state.brand.name}</Typography>
                <Typography variant="body1">Complete</Typography>
            </Box>
            <Box>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            { this.state.errors.healthy ? <CancelIcon /> : <CheckCircleIcon /> }
                        </ListItemIcon>
                        <ListItemText>Health Check {typeof this.state.errors.healthy == "string" ? (": " + this.state.errors.healthy) : ""}</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            { this.state.errors.privateAccess ? <CancelIcon /> : <CheckCircleIcon /> }
                        </ListItemIcon>
                        <ListItemText>Private Health Check {typeof this.state.errors.privateAccess == "string" ? (": " + this.state.errors.privateAccess) : ""}</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            { this.state.errors.newScheme ? <CancelIcon /> : <CheckCircleIcon /> }
                        </ListItemIcon>
                        <ListItemText>New Scheme {typeof this.state.errors.newScheme == "string" ? (": " + this.state.errors.newScheme) : ""}</ListItemText>
                    </ListItem>
                </List>
            </Box>
            <Box>
                <Button onClick={this.testBrand.bind(this, this.state.brand)}>Run Tests</Button>
            </Box>
            { this.state.error ? <Box><Typography variant="body1">Error: {this.state.errorMessage}</Typography></Box> : null}
            { this.state.passed ?
                <Box>
                    <Button onClick={this.createBrand.bind(this, this.state.brand)}>Create Brand</Button>
                </Box>
                : ""
            }
        </Container>
    }
}

export default withRouter(BrandComplete);
