import React, { Component} from "react";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { UserContext } from "../UserContext";

const mapStateToProps = state => {
  return {
    showMenu: state.showMenu,
  };
};
const dataForTheMenu = [
  { name: "Home", url: "/", icon: "home", id: 0 },
  { name: "Login", url: "/Login", icon: "home", id: 1 },
  { name: "Upload", url: "/Upload", icon: "home", id: 2 },
  { name: "Environments", url: "/EnvironmentList", icon: "home", id: 3 },
  { name: "Brands", url: "/Brand/list", icon: "home", id: 4 },
  { name: "Package Sets", url: "/PackageSet/list", icon: "home", id: 5 },
  { name: "Template", url: "/Template", icon: "template", id: 6 },
  { name: "Categories", url: "/Categories", icon: "categories", id: 7 },
  { name: "Export", url: "/ExportTacticsList", icon: "export", id: 8 }
];

class ConnectedMenu extends Component {
  static contextType = UserContext
  constructor(props) {
    super(props);

    this.state = {
      dataForTheMenu
    };

    this.renderMenu = this.renderMenu.bind(this)
  }

  isMenuItemActive(item, location) {

    if (location.pathname === "/" && location.search) {
      let queryStringParsed = queryString.parse(
        location.search
      );

      return (
        queryStringParsed.itemCategoryClicked === "true" && item.name === queryStringParsed.category
      );
    }

    return item.url === location.pathname;
  }

  renderMenu(data) {

    return (<List>
      {data
        .map((x, i) => {
            return (
              <NavLink
                to={x.url}
                exact
                isActive={(param, location) => { return this.isMenuItemActive(x, location) }}
                style={{
                  textDecoration: "none",
                  color: "rgb(32, 32, 34)",
                }}
                key={x.id}
                activeStyle={{
                  color: "#4282ad"
                }}
              >
                <ListItem dense button>
                  <ListItemText
                    disableTypography
                    primary={<div style={{ color: "inherit" }} >{x.name}</div>} />
                </ListItem>
              </NavLink>)
        })}
        { this.context.selectedBrand && <NavLink
            to={"/Brand/" + this.context.selectedBrand.id}
            exact
            style={{
              textDecoration: "none",
              color: "rgb(32, 32, 34)",
            }}
            key="brand"
            activeStyle={{
              color: "#4282ad"
            }}
          >
            <ListItem dense button>
              <ListItemText
                disableTypography
                primary={<div style={{ color: "inherit" }} >{"Selected Brand: " + this.context.selectedBrand.name}</div>} />
            </ListItem>
          </NavLink> }
        { this.context.selectedPackageSet  && <NavLink
            to={"/PackageSet/" + this.context.selectedPackageSet.id}
            exact
            style={{
              textDecoration: "none",
              color: "rgb(32, 32, 34)",
            }}
            key="packageSet"
            activeStyle={{
              color: "#4282ad"
            }}
          >
            <ListItem dense button>
              <ListItemText
                disableTypography
                primary={<div style={{ color: "inherit" }} >{"Selected Package Set: " + this.context.selectedPackageSet.name}</div>} />
            </ListItem>
          </NavLink> }
    </List >)
  }

  render() {
    if (!this.props.showMenu) return null;
    return (
      <div style={{
        backgroundColor: "#FAFAFB",
        minWidth: 250,
        maxWidth: "20%"
      }}>
        {/* Render our menu */}
        {this.renderMenu(this.state.dataForTheMenu)}
      </div>
    );
  }
}
const Menu = withRouter(connect(mapStateToProps)(ConnectedMenu));
export default Menu;
