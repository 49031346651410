import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { UserContext } from '../../UserContext'

import ParaObject from 'para-client-js/lib/ParaObject'
import queryString from 'query-string'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

/* Forms */
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

/* Grid */
import Grid from '@material-ui/core/Grid'
import { PackageSelector } from '../../components/PackageSelector'

let context

class PackageSetEdit extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props)

        this.state = {
            packageSet: { name: 'Unamed Package Set', state: 'Pending' },
            packageSetPackages: [],
            loading: true,
            isNew: true,
            openPackageSelector: false
        }
    }
    
    handleInputChange(e) {
        let { value, name } = e.target
        let packageSet = this.state.packageSet
        packageSet[name] = value
        this.setState({ packageSet: packageSet })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadPackageSet(this.props.match.params.id)
        }
    }

    componentDidMount() {
        this.isCompMounted = false
        let id = this.props.match.params.id
        
        if (id && id !== '0') {
            this.setState({brandId: id})
            this.loadPackageSet(this.props.match.params.id)
        } else {
            this.setState({ isNew: true, loading: false })
        }
        this.getEnvironmentList()
    }

    componentWillUnmount() {
        this.isCompMounted = false
    }

    async loadPackageSet(id) {
        this.setState({loading: true, isNew: false});
        const documentStore = this.context.documentStore;
        const packageSet = await documentStore.read('PackageSet', id);
        let statePackageSet = this.state.packageSet;

        ['name', 'state'].forEach((name) => {
            statePackageSet[name] = packageSet[name];
        });
        this.setState({packageSet: statePackageSet, loading: false, packageSetPackages: packageSet.packages || []});
    }

    async savePackageSet(id) {
        const documentStore = this.context.documentStore
        this.setState({ loading: true, isNew: id ? true : false })
        let result
        let packageSet

        if (id && id !== '0') {
            packageSet = await documentStore.read('PackageSet', id)
            packageSet = Object.assign(packageSet, this.state.packageSet, { packages: this.state.packageSetPackages })
            result = await documentStore.update(packageSet)
        } else {
            packageSet = new ParaObject()
            packageSet.type = 'PackageSet'
            packageSet.state = 'Pending'
            packageSet = Object.assign(packageSet, this.state.packageSet, { packages: this.state.packageSetPackages })
            result = await documentStore.create(packageSet)
        }

        this.setState({ loading: false, redirectToPackageSet: result.id })
    }

    async getEnvironmentList() {
        context = this.context
        let qsAsObject = queryString.parse(this.props.location.search)
        this.setState({ loading: true })

        let query = {
            type: 'Environment',
            q: qsAsObject.term ? qsAsObject.term : '*'
        }

        if (!qsAsObject.term) {
            query.sort = 'updated'
            query.desc = true
            query.limit = 20
        }

        let results = context.documentStore
            .getItems(await context.documentStore.find('', query))
            .map(function (environmentRes) {
                return {
                    id: environmentRes.id
                }
            })

        this.setState({
            environmentList: results,
            loading: this.state.packageSet && results ? false : true
        })

        // return results
    }

    setPackageSetPackages = (packages) => {
        this.setState({ packageSetPackages: packages })
    }

    render() {

        if (this.state.loading || !this.state.packageSet) {
            return <div id="packageLoading">
                <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
            </div>;
        }

        if (this.state.redirectToPackageSet) {
            return <Redirect to={'/PackageSet/' + this.state.redirectToPackageSet} />
        }

        return (
            <Container max-width="sm" style={{ padding: 10 }}>
                <Box my={6}>
                    <Typography variant="h1" component="h1">
                        Package Set Form
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h4" component="h4">
                            {this.state.isNew ? 'New Package Set' : this.state.packageSet.name}
                        </Typography>
                    </Grid>
                </Grid>

                <Box my={3}>
                    <FormControl>
                        <TextField
                            label="Name"
                            id="field-name"
                            name="name"
                            value={this.state.packageSet.name}
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </FormControl>
                </Box>

                <Box my={3}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Package Set State:</InputLabel>
                        <Select
                            style={{ width: '600px' }}
                            labelId="packageSet-state"
                            id="environment-select"
                            name="state"
                            value={this.state.packageSet.state}
                            onChange={this.handleInputChange.bind(this)}
                        >
                            {["Pending", "Completed"].map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={() => this.setState({ openPackageSelector: true })}
                        >
                            Manage Packages
                        </Button>
                    </Grid>
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.openPackageSelector}
                    onClose={() => this.setState({ openPackageSelector: false })}
                >
                    <AppBar style={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() =>
                                    this.setState({
                                        openPackageSelector: false
                                    })
                                }
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography style={{ flex: 1, marginLeft: '2%' }} variant="h5">
                                Manage Packages
                            </Typography>
                            <Button
                                autoFocus
                                color="inherit"
                                onClick={() =>
                                    this.setState({
                                        openPackageSelector: false
                                    })
                                }
                            >
                                Done
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <DialogContent>
                        <PackageSelector
                            selectedPackages={this.state.packageSetPackages}
                            brandId={this.state.packageSetId||null}
                        />
                    </DialogContent>
                </Dialog>

                <Box my={3}>
                    <FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.savePackageSet.bind(this, this.props.match.params.id)}
                        >
                            {this.state.isNew ? 'Create New' : 'Update'}
                        </Button>
                    </FormControl>
                </Box>
            </Container>
        )
    }
}

export default withRouter(PackageSetEdit)