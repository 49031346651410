import React from "react"
import { Link } from "react-router-dom" 

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography'

/* Grid */
import Grid from '@material-ui/core/Grid';

function PackageGridItem({pkg, itemId, type}) {
    if(!type) {
        type = "Brand"    
    }
    
    return <Grid item xs>
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                    { pkg.threeRadical.name }
                </Typography>
                <Typography color="textSecondary">
                    { pkg[type==="Brand"?"brand":"packageSet"].description }
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" component={Link} to={"/packagedetails/" + pkg.id}>Package</Button>
                <Button size="small" component={Link} to={"/" + type + "/" + itemId + "/removePackage/" + pkg.uuid}>Remove</Button>
            </CardActions>
        </Card>
    </Grid>
}

export default PackageGridItem
