import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { UserContext } from '../../UserContext'

import ParaObject from 'para-client-js/lib/ParaObject'
import queryString from 'query-string'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

/* Forms */
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

/* Grid */
import Grid from '@material-ui/core/Grid'
import { PackageSelector } from '../../components/PackageSelector'

let context

class BrandEdit extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props)

        this.state = {
            brand: { name: 'Unamed Brand', origins: [], environment: '', state: 'Pending' },
            brandPackages: [],
            loading: true,
            isNew: true,
            openPackageSelector: false
        }
    }
    
    handleOriginAdd(event) {
        if(event) {
            try {event.preventDefault()} catch(error) {}
            try {event.stopPropagation()} catch(error) {}
            if(event.target && 'FORM' === event.target.tagName) {
                let uri = (new FormData(event.target)).get('origin-uri');
                try {
                    uri = new URL(-1 === uri.indexOf('://') ? 'http://' + uri : uri);;
                } catch(error) {
                    uri = false;
                    alert(error.message.split(':').pop());
                }
                if(uri && !uri.origin) {
                    alert('Invalid URL');
                    uri = false;
                }
                if(false !== uri) {
                    uri = uri.origin;
                    event.target.reset();
                    let brand = this.state.brand;
                    if(!brand.origins) {brand.origins = [];}
                    if(uri && -1 === brand.origins.indexOf(uri)) {
                        brand.origins.push(uri);
                        this.setState({brand});
                    }
                }
            }
        }
    }

    handleOriginRemove(index) {
        if(!isNaN(index) && 0 <= index) {
            let brand = this.state.brand;
            if(brand.origins && 'string' === typeof(brand.origins[index]) && window.confirm('Are you sure you want to delete this Origin item?\n\n' + brand.origins[index])) {
                brand.origins.splice(index, 1);
                this.setState({brand});
            }
        }
    }

    handleInputChange(e) {
        let { value, name } = e.target
        let brand = this.state.brand
        brand[name] = value
        this.setState({ brand: brand })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadBrand(this.props.match.params.id)
        }
    }

    componentDidMount() {
        this.isCompMounted = false
        let id = this.props.match.params.id
        
        if (id && id !== '0') {
            this.setState({brandId: id})
            this.loadBrand(this.props.match.params.id)
        } else {
            this.setState({ isNew: true, loading: false })
        }
        this.getEnvironmentList()
    }

    componentWillUnmount() {
        this.isCompMounted = false
    }

    handleEnvironmentchange() {
        console.log('Handle Env change')
    }

    async loadBrand(id) {
        this.setState({loading: true, isNew: false});
        const documentStore = this.context.documentStore;
        const brand = await documentStore.read('Brand', id);
        let stateBrand = this.state.brand;

        ['name', 'environment', 'state', 'origins'].forEach((name) => {
            stateBrand[name] = brand[name];
        });
        this.setState({brand: stateBrand, loading: false, brandPackages: brand.packages || []});
    }

    async saveBrand(id) {
        const documentStore = this.context.documentStore
        this.setState({ loading: true, isNew: id ? true : false })
        let result
        let brand

        if (id && id !== '0') {
            brand = await documentStore.read('Brand', id)
            brand = Object.assign(brand, this.state.brand, { packages: this.state.brandPackages })
            result = await documentStore.update(brand)
        } else {
            brand = new ParaObject()
            brand.type = 'Brand'
            brand.state = 'Pending'
            brand = Object.assign(brand, this.state.brand, { packages: this.state.brandPackages })
            result = await documentStore.create(brand)
        }

        this.setState({ loading: false, redirectToBrand: result.id })
    }

    async getEnvironmentList() {
        context = this.context
        let qsAsObject = queryString.parse(this.props.location.search)
        this.setState({ loading: true })

        let query = {
            type: 'Environment',
            q: qsAsObject.term ? qsAsObject.term : '*'
        }

        if (!qsAsObject.term) {
            query.sort = 'updated'
            query.desc = true
            query.limit = 20
        }

        let results = context.documentStore
            .getItems(await context.documentStore.find('', query))
            .map(function (environmentRes) {
                return {
                    id: environmentRes.id
                }
            })

        this.setState({
            environmentList: results,
            loading: this.state.brand && results ? false : true
        })

        // return results
    }

    setBrandPackages = (packages) => {
        this.setState({ brandPackages: packages })
    }

    render() {

        if (this.state.loading || !this.state.brand || !this.state.environmentList) {
            return <div id="packageLoading">
                <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
            </div>;
        }

        if (this.state.redirectToBrand) {
            return <Redirect to={'/Brand/' + this.state.redirectToBrand} />
        }

        let environments = this.state.environmentList

        return (
            <Container max-width="sm" style={{ padding: 10 }}>
                <Box my={6}>
                    <Typography variant="h1" component="h1">
                        Brand Order Form
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h4" component="h4">
                            {this.state.isNew ? 'New Brand' : this.state.brand.name}
                        </Typography>
                    </Grid>
                </Grid>

                <Box my={3}>
                    <FormControl>
                        <TextField
                            label="Name"
                            id="field-name"
                            name="name"
                            value={this.state.brand.name}
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </FormControl>
                </Box>

                <Box my={3}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Environment:</InputLabel>
                        <Select
                            style={{ width: '600px' }}
                            labelId="environment"
                            id="environment-select"
                            name="environment"
                            value={this.state.brand.environment}
                            onChange={this.handleInputChange.bind(this)}
                        >
                            {environments.map((item) => {
                                return (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.id}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>
                
                <Box>
                    <FormControl>
                        <form onSubmit={this.handleOriginAdd.bind(this)}>
                            <label className="MuiFormLabel-root">Origins:</label>
                            <div>
                                <TextField label="URI" name="origin-uri" />
                                <button
                                    type="submit"
                                    value="add"
                                    style={{
                                        backgroundColor: '#51b53f',
                                        height: '40px',
                                        width: '40px',
                                        borderRadius: '20px',
                                        border: 0,
                                        marginTop: '6px',
                                        lineHeight: '40px',
                                        textAlign: 'center'}}
                                    >+</button>
                            </div>
                        </form>
                        <ul style={{listStyle: 'none'}}>{(this.state.brand.origins || []).map((item, index) => {return (
                            <li key={index} style={{lineHeight: '24px', minHeight: '26px', backgroundColor: index % 2 ? 'rgba(0, 0, 0, 0.2)' : 'transparent', borderRadius: '4px', padding: '4px'}}>
                                <button
                                    type="submit"
                                    value="remove"
                                    onClick={this.handleOriginRemove.bind(this, index)}
                                    style={{
                                        backgroundColor: '#b5513f',
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '10px',
                                        border: 0,
                                        lineHeight: '18px',
                                        textAlign: 'center',
                                        marginRight: '20px'
                                    }}
                                    >-</button>
                                <span>{item}</span>
                            </li>
                        )})}</ul>
                    </FormControl>
                </Box>

                {/* Only for development */}
                {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && <Box my={3}>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">Brand State:</InputLabel>
                        <Select
                            style={{ width: '600px' }}
                            labelId="brand-state"
                            id="environment-select"
                            name="state"
                            value={this.state.brand.state}
                            onChange={this.handleInputChange.bind(this)}
                        >
                            {["Pending", "Completed"].map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Box>}

                <Box>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={() => this.setState({ openPackageSelector: true })}
                        >
                            Manage Packages
                        </Button>
                    </Grid>
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.openPackageSelector}
                    onClose={() => this.setState({ openPackageSelector: false })}
                >
                    <AppBar style={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() =>
                                    this.setState({
                                        openPackageSelector: false
                                    })
                                }
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography style={{ flex: 1, marginLeft: '2%' }} variant="h5">
                                Manage Packages
                            </Typography>
                            <Button
                                autoFocus
                                color="inherit"
                                onClick={() =>
                                    this.setState({
                                        openPackageSelector: false
                                    })
                                }
                            >
                                Done
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <DialogContent>
                        <PackageSelector
                            selectedPackages={this.state.brandPackages}
                            brandId={this.state.brandId||null}
                        />
                    </DialogContent>
                </Dialog>

                {/*<Box my={3}>
			<FormControl>
				<InputLabel id="demo-simple-select-label">Authentication:</InputLabel>
				<Select style={{width: "600px"}}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={10}
					onChange={this.handleChange}
				>
					<MenuItem value={10}>SAML</MenuItem>
					<MenuItem value={20}>JWT</MenuItem>
					<MenuItem value={30}>HMAC</MenuItem>
					<MenuItem value={40}>Web Service</MenuItem>
					<MenuItem value={50}>Username / Password</MenuItem>
					<MenuItem value={50}>Other - Please Specify</MenuItem>
				</Select>
			</FormControl>
		</Box>


		<Box my={3}>
			<FormControl>
				<InputLabel id="demo-simple-select-label">When does Registration take place?</InputLabel>
				<Select style={{width: "600px"}}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={10}
					onChange={this.handleChange}
				>
					<MenuItem value={10}>Before access to anything else</MenuItem>
					<MenuItem value={20}>Guest mode then registering later</MenuItem>
				</Select>
			</FormControl>
        </Box>
        
        <Box my={3}>
			<FormControl>
				<InputLabel id="demo-simple-select-label">How will 3radical users access the live solution?</InputLabel>
				<Select style={{width: "600px"}}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={10}
					onChange={this.handleChange}
				>
					<MenuItem value={10}>Email</MenuItem>
					<MenuItem value={20}>User ID</MenuItem>
				</Select>
			</FormControl>
        </Box>
        
        <Box my={3}>
			<FormControl>
				<InputLabel id="demo-simple-select-label">What data (not from registration questions) is required?</InputLabel>
				<Select style={{width: "600px"}}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={10}
					onChange={this.handleChange}
				>
					<MenuItem value={10}>Lookup Service</MenuItem>
					<MenuItem value={20}>Whitelist Upload</MenuItem>
					<MenuItem value={30}>URL Parameters / HMAC</MenuItem>
				</Select>
			</FormControl>
        </Box>

        <Box my={3}>
			<FormControl>
				<InputLabel id="demo-simple-select-label">What external events does Voco need to react to (Wagering, Click stream, Conversions etc) ?</InputLabel>
				<Select style={{width: "600px"}}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={10}
					onChange={this.handleChange}
				>
					<MenuItem value={10}>Individual - Unlock / Queue Unlock</MenuItem>
					<MenuItem value={20}>Aggregate - Real Time Events system</MenuItem>
				</Select>
			</FormControl>
        </Box>

        <Box my={3}>
			<FormControl>
				<Typography component="div">
					<Grid component="label" container alignItems="center" spacing={1}>
						<Grid item>Private</Grid>
						<Grid item>
							<Switch onChange={this.handleChange}  name="checkedC" />
						</Grid>
						<Grid item>Public</Grid>
					</Grid>
				</Typography>
			</FormControl>
        </Box>*/}

                <Box my={3}>
                    <FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.saveBrand.bind(this, this.props.match.params.id)}
                        >
                            {this.state.isNew ? 'Create New' : 'Update'}
                        </Button>
                    </FormControl>
                </Box>
            </Container>
        )
    }
}

export default withRouter(BrandEdit)