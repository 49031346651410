import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationBox({ButtonContent, title, message, handler, style}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = async (choice, e) => {
        setOpen(false);
        await handler(choice)
    };

    return (
        <div>
            <Button style={style} variant="contained" color="primary" onClick={handleClickOpen}>
                {
                    typeof ButtonContent === "string" ? 
                        (<div dangerouslySetInnerHTML={{__html: ButtonContent}} />) : 
                        (<ButtonContent />)    
                }
            </Button>
            <Dialog open={open} onClose={handleClose.bind(this, "close")} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"> <div dangerouslySetInnerHTML={{__html: title}} /> </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: message}} />
                </DialogContent>
                <DialogActions>
                    <Button id="confirmationDisagree" onClick={handleClose.bind(this, "disagree")} color="secondary">
                        Disagree
                    </Button>
                    <Button id="confirmationAgree" onClick={handleClose.bind(this, "agree")} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}