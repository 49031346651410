import React, { Component } from "react";
import '../common.css';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import cartImage from "../Images/logo.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

class ConnectedSearch extends Component {
  state = {
    searchTerm: ""
  };

  render() {
    return (
      <AppBar
        position="static"
        style={{ backgroundColor: "#fff", padding: 10 }}
      >
        <Toolbar>
          <div className="left-part">
            <img
              src={cartImage}
              alt={"Logo"}
              style={{ marginLeft: 10 }}
            />
            <TextField
              label="Search packages"
              value={this.state.searchTerm}
              onChange={e => {
                this.setState({ searchTerm: e.target.value });
              }}
              onKeyPress={(e) => { if (e.key === "Enter") { doSearch(this.props, this.state) } } }
              style={{ marginLeft: "20%", width: "30%", marginBottom: 15 }}
            />

            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              color="primary"
              onClick={() => { return doSearch(this.props, this.state) } }
            >
              {" "}
              Search
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

function doSearch(props, state) {
    if (props.location.pathname === "/PackageList") {
        props.history.push(
          "/?term=" +
          state.searchTerm
        );
    } else {
        props.history.push(
          "/PackageList?term=" +
          state.searchTerm
        );
    }
}

const Search = withRouter(ConnectedSearch);
export default Search;
