import React from 'react'
import PackageList from '../PackageList'
import CategoriesList from './CategoriesList'

function Categories() {
    return (
        <div>
            <CategoriesList />
        </div>
    )
}

export default Categories
