import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from "query-string";
import config from "../config";
import Item from "./Items";
import { withRouter } from "react-router-dom";
import { UserContext } from "../UserContext";

let context;
class PackageList extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      hovering: "",
    };
  }

  getTitle() {
    let pageTitle;
    //console.log("this.props", this.props)
    if (
      this.props.match.url === "/" &&
      this.props.match.path === "/" &&
      this.props.location.search === ""
    ) {
      pageTitle = "New Tactics";
    } else {
      pageTitle = "Search Results";
    }
    return pageTitle;
  }

  async fetchData() {
    this.setState({ loading: true });
    context = this.context;
    let searchQuery = this.props.location.search.replace("?term=","").toLowerCase();

    // Make the request to get items
    let pager = new context.documentPager(1, "updated", true, 9999)
    try {
        let pkgDoc = await context.documentStore.read("Package", searchQuery)

        if (pkgDoc) {
            window.location = "#/packagedetails/" + searchQuery; 
        }

    } catch (e) {

    }
    let paraData = await context.documentStore.findQuery("Package", "*", pager)
    var searchQueryWords = searchQuery.replace("%20","").split(" ")
    var IDFs = []
    
    searchQueryWords.forEach(function(word){
      var IDFContainCount = 0
      
      paraData.forEach(function(pack){
        if(pack.id.replace("-","").includes(word) || (pack.threeRadical && pack.threeRadical.name && pack.threeRadical.name.toLowerCase().includes(word)) || (pack.threeRadical &&  pack.threeRadical.description && pack.threeRadical.description.toLowerCase().includes(word))){
          IDFContainCount = IDFContainCount+1
        }
      })
      var IDFValue
      if(IDFContainCount == 0){
          IDFValue = 0
        } else {
          IDFValue = Math.log(paraData.length/IDFContainCount)
        }
      IDFs.push(IDFValue)
      
    })

    let results = Object.entries(paraData)
    .map(function (pkgRes) {
      pkgRes = pkgRes[1]

      var TFCheck = pkgRes.id.replace("-","") 
      if(pkgRes.threeRadical){
        TFCheck = TFCheck + " " + pkgRes.name+ " " + pkgRes.threeRadical.description
      }
      
      var totalWords = TFCheck.split(" ").length
      var TFs = []
      
      searchQueryWords.forEach(function(word){
        TFs.push((TFCheck.split(word).length-1)/totalWords)
      })
      
      var rankTotal = 0
      for(var i=0; i<IDFs.length; i++){
        var TFIDF = TFs[i]*IDFs[i]
        rankTotal = rankTotal + TFIDF
      }
      var rankScore = rankTotal/IDFs.length
      
      if(searchQueryWords[0] == '' && searchQueryWords.length == 1){
        rankScore = 1
      }
      
      if(rankScore > 0 && pkgRes.package && pkgRes.package.version && pkgRes.package.version != null){
        let threeRadical = pkgRes.threeRadical || {};
        let pkg = pkgRes.package || {};
        return {
          id: pkgRes.id,
          name: threeRadical.name || pkgRes.id,
          rankScore: rankScore,
          imageUrl: (threeRadical.icon || "").startsWith("http")
            ? pkgRes.threeRadical.icon
            : config.defaultIcon,
          version: pkg.version,
          ...threeRadical,
        };
      }
    })

    results = results.filter(function( element ) {
      return element !== undefined;
    });
    
    results.sort(function (a, b) {
      return (a.rankScore < b.rankScore) ? 1 : -1;
    });
  
    this.setState({
      items: results,
      loading: false,
    });

    return results;
  }

  componentDidMount() {
    this.fetchData();
  }

  filterByName(data, Name) {
    var dest = [];
    for (var i = 0; i < data.length; i++) {
      var ai = data[i];
      if (i === 0) {
        dest.push(ai);
      } else {
        var filterData = dest.filter(function (e) {
          return e[Name] === ai[Name];
        });
        if (filterData.length === 0) {
          dest.push(ai);
        }
      }
    }
    return dest;
  }

  handleHover = (value, i) => {
    switch (value) {
      case "enter":
        this.setState({ hovering: i });
        break;
      case "leave":
        this.setState({ hovering: false });
        break;
      default:
        this.setState({});
    }
  };

  render() {
    // Check if packagess are loading ...
    if (this.state.loading) {
      return <CircularProgress className="circular" />;
    }
    let itemLength;

    if (
      this.props.match.url === "/" &&
      this.props.match.path === "/" &&
      this.props.location.search === ""
    ) {
      itemLength = 4;
    } else {
      itemLength = this.state.items.length;
    }

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, fontSize: 24 }}>
            <div>{this.getTitle()}</div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          {this.state.items.slice(0, itemLength).map((item, i) => {
            return (
              <span
                key={item.id}
                onMouseEnter={() => this.handleHover("enter", i)}
                onMouseLeave={() => this.handleHover("leave")}
              >
                <Item
                  key={item.id}
                  item={item}
                  hovering={this.state.hovering}
                  i={i}
                />
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(PackageList);
