import React, { Component } from 'react'
import { withRouter, Redirect, Link } from 'react-router-dom'
import { UserContext } from '../../UserContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { Box, Typography } from '@material-ui/core'
import ConfirmationBox from "../../components/ConfirmationBox/ConfirmationBox";
import PackageGridItem from '../../components/presentational/PackageGridItem'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

class PackageSet extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)

        this.state = {
            packageSet: { name: 'Unamed Package Set' },
            loading: true,
            isNew: true,
            redirectToEdit: false,
            isNewOnItem: true
        }
        
        this.removePackageSet = this.removePackageSet.bind(this)
    }

    componentDidMount() {
        this.isCompMounted = true
        let id = this.props.match.params.id

        this.setState({ redirectToEdit: false })

        if (id && id !== '0') {
            this.loadPackageSet(this.props.match.params.id)
        } else {
            this.setState({ isNew: true, loading: false })
        }

        if (this.context.selectedBrand) {
          this.setItem(this.context.selectedBrand, "Brand");
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false
    }

    async setItem(item, type) {
        const documentStore = this.context.documentStore
        let fullItem
        try {
          fullItem = await documentStore.read(type, item.id)
          
          if(fullItem.packages.filter(pkg => { return pkg.id === this.props.match.params.id }).length > 0){
            this.setState({ isNewOnItem: false })
          }
        } catch(e){
          console.warn("Error while retrieving " + type + " document from store: ", e)
        }
        
        let result = {}
        if(type === "Brand") {
          this.setState({ brand: fullItem ? fullItem : ( item ? item : false ) });
        }
    }

    async loadPackageSet(id) {
        this.setState({ loading: true, isNew: false })
        const documentStore = this.context.documentStore
        const packageSet = await documentStore.read('PackageSet', id)

        const selectedPackageSet = this.context.selectedPackageSet

        if (!selectedPackageSet || selectedPackageSet.id !== packageSet.id) {
            this.context.setSelectedPackageSet(packageSet)
        }

        this.setState({ packageSet: packageSet, loading: false })
    }
    
    async removePackageSet(choice) {
        if(choice === "agree"){
            await this.setState({installing: true})
            let existingPkgSet = (await this.context.documentStore.findById(this.props.match.params.id) || []).filter(item => {
                return item.type === "PackageSet"
            })[0]
            let deleteResult = await this.context.documentStore.delete(existingPkgSet)
            
            alert("Package Set deleted.")
            
            this.context.setSelectedPackageSet(false)
            await this.setState({redirectToPackageSetList: true})
        }
    }

    redirectToEdit() {
        this.setState({ redirectToEdit: true })
    }

    render() {
        if (this.state.loading) {
            return <div id="packageLoading">
                <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
            </div>;
        }

        if (this.state.redirectToEdit) {
            return <Redirect to={'/PackageSet/' + this.state.packageSet.id + '/edit'} />
        }
        
        if(this.state.redirectToPackageSetList) {
            return <Redirect to={'/PackageSet/list'} />
        }

        return (
            <Container>
                <Box my={6}>
                    <Typography variant="h3" component="h2">
                        Package Set: {this.state.packageSet.name}
                    </Typography>
                    <Typography variant="body1">{this.state.packageSet.strapline}</Typography>
                </Box>
                
                <Box my={6}>
                    <Typography variant="h4">Packages</Typography>
                    {this.state.packageSet.state === "Pending" && <div
                          style={{
                            marginTop: 10,
                            fontSize: 18,
                            overflow: "auto"
                          }}
                        >
                            This Package Set needs to be in a Completed state before it can be installed on any brand
                        </div>
                    }
                </Box>

                <Grid container spacing={3}>
                    {(this.state.packageSet.packages || []).map((pkg) => (
                        <PackageGridItem pkg={pkg} itemId={this.state.packageSet.id} key={pkg.uuid} type="PackageSet" />
                    ))}
                </Grid>

                <Box>
                    <FormControl>
                        {this.state.brand && this.state.packageSet.state === "Completed" && 
                            <Button
                                style={{ marginTop: 10, marginBottom: 10, width: 150 }}
                                variant="contained"
                                component={Link}
                                to={'/Brand/' + this.state.brand.id + '/addPackageSet/' + this.state.packageSet.id}
                              >
                                {" "}
                                { this.state.isNewOnItem ? "Add to Brand" : "Reinstall on Brand" } {this.state.brand.name}
                            </Button>
                        }
                        <Button 
                            style={{ marginTop: 10, marginBottom: 10, width: 150 }}
                            variant="contained" 
                            component={Link} 
                            to={'/PackageSet/' + this.state.packageSet.id + '/edit'}>
                            Edit
                        </Button>
                        
                        <ConfirmationBox
                            style={{ marginTop: 10, marginBottom: 10, width: 150, backgroundColor: "#f82b2a", color: "white" }}
                            ButtonContent={"Delete"}
                            title="Delete Package Set?" 
                            message="Deleting the Package Set will not affect VTS packages but is irreversible. Proceed?" 
                            handler={this.removePackageSet} 
                        />
                    </FormControl>
                </Box>
            </Container>
        )
    }
}

export default withRouter(PackageSet)
