import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import {Box, TextField, Button, IconButton, Card, Divider, FormGroup, CardContent, Checkbox  } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import { UserContext } from "../UserContext"
import ParaObject from 'para-client-js/lib/ParaObject';
import { request, logger } from 'react-voco-upload/src/shared/utils'
import { docToItem } from "../lib/environment.js"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import '../common.css';
import config from '../config'
// import { file } from '@babel/types'

let context;

class ConnectedEditEnvironment extends Component {
    constructor(props) {
        super(props);        
        this.isCompMounted = false;

        this.state = {
            page: "add",
            newDomainPart: "",
            environment: {
                id : "",
                deploymentInfo: {
                        domainParts: [],
                        domain: "",
                        services: {
                            gtp: {
                                notinsecure : true,
                                domainPart: "",
                                hmac: {
                                    version: 1,
                                    maxAge: 30000
                                },
                            secret: ""
                        }
                    }
                }            
            },
            loading : false, 
            error : false, 
            message : ""
        }

        this.handleChangeEvent = this.handleChangeEvent.bind(this)
        this.setEnvironment = this.setEnvironment.bind(this)
        this.addDomainPart = this.addDomainPart.bind(this)
        this.onDomainPartUpdate = this.onDomainPartUpdate.bind(this)
        this.deleteDomainPart = this.deleteDomainPart.bind(this)
    }

    componentDidMount() {
        this.isCompMounted = true;
        if(this.props.match.params.id) {
            this.setState({ 
                page: "edit"
            })

            this.fetchProductUsingID(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps) {   
        // If ID of environment changed in URL, refetch PackageDetails
        if (this.props.match.params.id !== prevProps.match.params.id) {
          if(this.props.match.params.id) {
            this.fetchProductUsingID(this.props.match.params.id);
          } else {
            this.setState({
                page: "add",
                environment: {
                    id : "",
                    deploymentInfo: {
                            domainParts: [],
                            domain: "",
                            services: {
                                gtp: {
                                    notinsecure : true,
                                    domainPart: "",
                                    hmac: {
                                        version: 1,
                                        maxAge: 30000
                                    },
                                secret: ""
                            }
                        }
                    }            
                },
                loading : false, 
                error : false, 
                message : ""
            })
          }
        }        
    }
    
    // Get environment
    async fetchProductUsingID(id) {
        context = this.context
        this.setState({ loading: true })
        let environmentDoc = (await context.documentStore.findById(id) || []).filter(item => {
            return item.type == "Environment"
        })[0]

        if (!environmentDoc) {
            this.setState({ loading : false, error : true, message : id + " not found" })
        }

        if (this.isCompMounted) {
            this.setState({
                loading : false,
                environment : docToItem(environmentDoc)
            })
        }

        return environmentDoc
    }

    setEnvironment = async () => {
        let environment = this.state.environment
        if(!environment.id){
            alert("ID is required")
            return 
        }

        if(this.state.page == "add") {
            if((await context.documentStore.findById(environment.id) || []).filter(item => {
                return item.type == "Environment"
            }).length > 0) {
                alert("Another environment with this id already exists.")
                return
            }
            let newEnvironment = new ParaObject(environment.id);
            newEnvironment.type = "Environment"
            newEnvironment.environment = environment
            let self = this
            context.documentStore.create(newEnvironment).then(function (environmentObject) {
                alert("New environment created.")
                self.props.history.push("/EnvironmentList") 
            }, function (err) {
                console.warn(err, err.stack)
            });
        } else if(this.state.page == "edit") {
            let existingEnvironment = (await context.documentStore.findById(environment.id) || []).filter(item => {
                return item.type == "Environment"
            })[0]
            existingEnvironment.environment = environment

            context.documentStore.update(existingEnvironment).then(function (environmentObject) {
                alert("Environment updated.")
            }, function (err) {
                console.warn(err, err.stack)
            });
        }
    }

    addDomainPart = () => {
        let environment = {...this.state.environment}
        environment.deploymentInfo.domainParts.push(this.state.newDomainPart)
        this.setState({
            newDomainPart: '',
            environment: environment
        })  
    }

    onDomainPartUpdate = (e) => {
        this.setState({ newDomainPart: e.currentTarget.value })
    }

    deleteDomainPart = (index) => {
        let environment = {...this.state.environment}
        environment.deploymentInfo.domainParts.splice(index, 1)
        this.setState({environment: environment})  
    }

    handleChangeEvent = (e) => {
        let environment = {...this.state.environment}
        switch(e.currentTarget.name) {
            case "id": {
                environment.id = e.currentTarget.value.trim()
                break
            }
            case "deploymentInfo.domain": {
                environment.deploymentInfo.domain = e.currentTarget.value
                break
            }
            case "deploymentInfo.services.gtp.domainPart": {
                environment.deploymentInfo.services.gtp.domainPart = e.currentTarget.value
                break
            }
            case "deploymentInfo.services.gtp.notinsecure": {
                environment.deploymentInfo.services.gtp.notinsecure = e.currentTarget.checked
                break
            }
            case "deploymentInfo.services.secret": {
                environment.deploymentInfo.services.secret = e.currentTarget.value
                break
            }
            case "deploymentInfo.services.gtp.hmac.version": {
                environment.deploymentInfo.services.gtp.hmac.version = +e.currentTarget.value
                break
            }
            case "deploymentInfo.services.gtp.hmac.maxAge": {
                environment.deploymentInfo.services.gtp.hmac.maxAge = +e.currentTarget.value
                break
            }
            default: {
                break
            }
        }         
        this.setState({environment: environment})  
    }

    static contextType = UserContext

    render() {
        context = this.context
        let content
        content = (
            <Container>
                <Typography variant="h4" component="h4" className="section center">
                    {this.state.page == "add" ? "Add" : "Edit"} Environment
                </Typography>
                <div className="section">
                    <div className="cm-col-100 cm-row">
                        <TextField label="ID*" variant="outlined" className="pkg-field" name="id" onChange={this.handleChangeEvent} value={(this.state.environment || {}).id} InputProps={{
                            readOnly: this.state.page == "edit"
                        }}/>
                    </div>
                    <div className="cm-col-100 cm-row">
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary">DEPLOYMENT INFO</Typography>
                                <div className="cm-col-100 cm-row">
                                    <TextField label="Add new domain part" value={this.state.newDomainPart} onChange={this.onDomainPartUpdate} />
                                    <Button variant="raised" color="primary" disabled={!this.state.newDomainPart} onClick={this.addDomainPart} >Add</Button>                  
                                </div>
                                <div className="cm-col-100 cm-row">
                                    {this.state.environment.deploymentInfo.domainParts.length > 0 && (
                                        <Card>
                                            <Typography color="textSecondary">DOMAIN PARTS</Typography>
                                            <FormGroup>
                                                {this.state.environment.deploymentInfo.domainParts.map((domainPart, index) => (
                                                    <div key={index}>
                                                    {index > 0 ? <Divider /> : ""}                                    
                                                    {domainPart}
                                                    <Tooltip title="Delete domain part" placement="top">
                                                        <IconButton
                                                        aria-label="delete"
                                                        onClick={() => this.deleteDomainPart(index)}
                                                        >               
                                                        <DeleteIcon />                         
                                                        </IconButton>
                                                    </Tooltip>
                                                    </div>
                                                ))}
                                            </FormGroup>
                                        </Card>
                                    )}
                                </div>
                                <div className="cm-col-100 cm-row">
                                    <TextField label="DOMAIN" variant="outlined" className="pkg-field" name="deploymentInfo.domain" onChange={this.handleChangeEvent} value={(this.state.environment || {}).deploymentInfo.domain}/>
                                </div>
                                <div className="cm-col-100 cm-row">
                                    <Card>
                                        <CardContent>
                                            <Typography color="textSecondary">SERVICES</Typography>
                                            <div className="cm-col-100 cm-row">
                                                <Card>                                                
                                                    <CardContent>
                                                        <Typography color="textSecondary">GTP</Typography>
                                                        <div className="cm-col-100 cm-row">
                                                            <TextField label="DOMAIN PART" variant="outlined" className="pkg-field" name="deploymentInfo.services.gtp.domainPart" onChange={this.handleChangeEvent} value={(this.state.environment || {}).deploymentInfo.services.gtp.domainPart}/>
                                                        </div>
                                                        <div className="cm-col-100 cm-row">
                                                            <Checkbox name="deploymentInfo.services.gtp.notinsecure" onChange={this.handleChangeEvent} checked={this.state.environment.deploymentInfo.services.gtp.notinsecure} tabIndex={-1} disableRipple/> NOT INSECURE?
                                                        </div>
                                                        <div className="cm-col-100 cm-row">
                                                            <Card>
                                                                <CardContent>
                                                                    <Typography color="textSecondary">HMAC</Typography>
                                                                    <div className="cm-col-100 cm-row">
                                                                        <TextField type="number" label="VERSION" variant="outlined" className="pkg-field" name="deploymentInfo.services.gtp.hmac.version" onChange={this.handleChangeEvent} value={(this.state.environment || {}).deploymentInfo.services.gtp.hmac.version}/>
                                                                    </div>
                                                                    <div className="cm-col-100 cm-row">
                                                                        <TextField type="number" label="MAX AGE" variant="outlined" className="pkg-field" name="deploymentInfo.services.gtp.hmac.maxAge" onChange={this.handleChangeEvent} value={(this.state.environment || {}).deploymentInfo.services.gtp.hmac.maxAge}/>
                                                                    </div>
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            <div className="cm-col-100 cm-row">
                                                <TextField label="SECRET" variant="outlined" className="pkg-field" name="deploymentInfo.services.secret" onChange={this.handleChangeEvent} value={(this.state.environment || {}).deploymentInfo.services.secret}/>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="cm-col-100 cm-row">
                        <div className="cm-col">
                            <Button variant="contained" component="span" color="secondary" onClick={this.setEnvironment} className="cm-btn">Save</Button>
                        </div>
                    </div>
                </div>
            </Container>
        )
        
        return content
    }
}

let EditEnvironment = connect()(ConnectedEditEnvironment);
export default withRouter(EditEnvironment);
