import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {UserContext} from "../UserContext"

import config from "../config"

export default class Login extends Component {
    static contextType = UserContext
    render() {
        let me = this.context.me
        return (
            <Container maxWidth="sm">
                <Box my={4}>
                    <Typography variant="h1" component="h1">
                        Login
                    </Typography>
                    <Typography component="p">
                        {me ? me.email : "Not Logged In" }
                    </Typography>
                    <Button href={
                        "https://accounts.google.com/o/oauth2/v2/auth?client_id=" + 
                        config.appId +
                        "&response_type=code&scope=openid%20email&redirect_uri=https://para.vocohub.com/google_auth&state=" +
                        config.accessKey + 
                        "&openid.realm=" +
                        config.endpoint} >
                        Login
                    </Button>
                </Box>
            </Container>
        )
    }
}
