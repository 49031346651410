import React, { Component } from 'react'
import { withRouter, Redirect, Link } from 'react-router-dom'
import { UserContext } from '../UserContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { Box, Typography } from '@material-ui/core'
import PackageGridItem from '../components/presentational/PackageGridItem'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

class Brand extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props)

        this.state = {
            brand: { name: 'Unamed Brand' },
            loading: true,
            isNew: true,
            redirectToEdit: false
        }
    }

    componentDidMount() {
        this.isCompMounted = true
        let id = this.props.match.params.id

        this.setState({ redirectToEdit: false })

        if (id && id !== '0') {
            this.loadBrand(this.props.match.params.id)
        } else {
            this.setState({ isNew: true, loading: false })
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false
    }

    async loadBrand(id) {
        this.setState({ loading: true, isNew: false })
        const documentStore = this.context.documentStore
        const brand = await documentStore.read('Brand', id)

        const selectedBrand = this.context.selectedBrand

        if (!selectedBrand || selectedBrand.id !== brand.id) {
            this.context.setSelectedBrand(brand)
        }

        this.setState({ brand: brand, loading: false })
    }

    redirectToEdit() {
        this.setState({ redirectToEdit: true })
    }

    render() {
        if (this.state.loading) {
            return <div id="packageLoading">
                <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
            </div>;
        }

        if (this.state.redirectToEdit) {
            return <Redirect to={'/Brand/' + this.state.brand.id + '/edit'} />
        }

        return (
            <Container>
                <Box my={6}>
                    <Typography variant="h1" component="h1">
                        {this.state.brand.name}
                    </Typography>
                    <Typography variant="body1">{this.state.brand.strapline}</Typography>
                </Box>
                <Box my={6}>
                    <Typography variant="h2">Packages</Typography>
                </Box>

                <Grid container spacing={3}>
                    {(this.state.brand.packages || []).map((pkg) => (
                        <PackageGridItem pkg={pkg} itemId={this.state.brand.id} key={pkg.uuid} type="Brand" />
                    ))}
                </Grid>

                <Box>
                    <FormControl>
                        <Button variant="contained" component={Link} to={'/Brand/' + this.state.brand.id + '/edit'}>
                            Edit
                        </Button>
                        <Button variant="contained" component={Link} to={'/Brand/' + this.state.brand.id + '/Complete'}>
                            Complete
                        </Button>
                    </FormControl>
                </Box>
            </Container>
        )
    }
}

export default withRouter(Brand)
