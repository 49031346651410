import config from "../config"
import VocoInterface from "app-context/voco-interface"

import semver from 'semver';
import _ from "lodash";

export function docToItem (pkgRes) {
    let threeRadical = pkgRes.threeRadical || {}
    let pkg = pkgRes.package || {}
    return {
        id : pkgRes.id,
        name : threeRadical.name || pkgRes.id,
        imageUrl : (threeRadical.icon || "").startsWith("http") ? pkgRes.threeRadical.icon : config.defaultIcon,
        version : pkg.version,
        description : pkg.description
    }
}

export async function validateDependencies(pkg, packages){
    let dependencies = _.get(pkg, "threeRadical.depends", [])
    let missing = []
    
    let isDemo = config.apiAddress.replace(/https?:\/\//, "").split(".")[0].split("-")[0]
    isDemo = (["local", "demo"].indexOf(isDemo) > -1)
    
    for(let i = 0; i < dependencies.length; i++){
        let d = dependencies[i].split("@")
        let pass = false
        if(!d[1] && /[?]$/.test(d[0])) {
            if(isDemo){ console.log(d[0] + " - dependency is optional. Pass = true") }
            pass = true
        } else {
            for(let j = 0; j < packages.length; j++){
                if(pass){ break; }
                
                if (d[1] && d[0] === packages[j].id) {
                    if(semver.satisfies(packages[j].version, d[1])){
                        if(isDemo){ console.log(d[0] + " - Semantic Versioning check passed. ", packages[j].version, d[1], " Pass = true") }
                        pass = true
                    } else {
                        console.log(d[0] + " - Semantic Versioning check failed. ", packages[j].version, d[1], " Pass = false")
                    }
                } else {
                    if(d[0] === packages[j].id || packages[j].provides.indexOf(d[0]) > -1){
                        if(isDemo){ console.log(d[0] + " - Corresponding dependency " + d[0] + " found. Pass = true") }
                        pass = true
                    }
                }
            }
        }
        
        if(!pass){
            if(isDemo){ console.log("- Dependency " + dependencies[i] + " is missing") }
            missing.push(dependencies[i])
        }
    }
    
    return { ok: !missing.length, missing: missing }
}