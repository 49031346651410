import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

export const PackageCard = ({ info }) => {
    return (
        <Card
            style={{
                width: 200,
                height: '100%',
                display: 'inline-block'
            }}
        >
            <CardMedia style={{ height: 140 }} image={info.imageUrl} />
            <CardContent style={{ height: 50 }}>
                <div
                    style={{
                        marginLeft: 5,
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {info.threeRadical.name || info.id}
                </div>
                <div style={{ margin: 5 }}>Version: {info.version}</div>
            </CardContent>
        </Card>
    )
}
