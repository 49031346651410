import React from "react"
import { Link } from "react-router-dom" 

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography'

/* Grid */
import Grid from '@material-ui/core/Grid';

function GridItem({ item, type }) {
    if(!type) {
        type = "Brand"
    }
    
    return <Grid item xs>
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                    { item.name }
                </Typography>
                <Typography color="textSecondary">
                    { item.strapline }
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" component={Link} to={"/" + type + "/" + item.id}>Learn More</Button>
            </CardActions>
        </Card>
    </Grid>
}

export default GridItem
