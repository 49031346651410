import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from "query-string";
import config from '../config'
import EnvironmentItem from "./EnvironmentItem";
import { withRouter } from "react-router-dom";
import { UserContext } from "../UserContext"
import Button from "@material-ui/core/Button"

let context;
class EnvironmentList extends Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      items: []
    };

    this.deleteEnvironment = this.deleteEnvironment.bind(this)
  }

  getTitle() {
      let pageTitle
      if (this.props.match.url === "/" && this.props.match.path === "/" && this.props.location.search === "") {
          pageTitle = "New Tactics";
      } else {
          pageTitle = "Environments";
      }
      return pageTitle;
  }

  async fetchData() {

    this.setState({ loading: true });
    context = this.context
    let qsAsObject = queryString.parse(this.props.location.search);

    // Make the request to get items

    let query = {
        type : "Environment",
        q : qsAsObject.term ? qsAsObject.term : "*"
    }

    if (!qsAsObject.term) {
        query.sort = "updated"
        query.desc = true
        query.limit = 20
    }

    let results = context.documentStore.getItems((await context.documentStore.find("", query)))
        .map(function (environmentRes) {
            return {
                id : environmentRes.id
            }
        })

    this.setState({
      items: results,
      loading: false
    });

    return results
  }

  componentDidMount() {
    this.fetchData();
  }

  filterByName(data, id) { 
    var dest = [];
    for (var i = 0; i < data.length; i++) {
        var ai = data[i];
        if (i === 0) {
            dest.push(ai);
        } else {
            var filterData = dest.filter(function (e) {
                return e[id] === ai[id];
            })
            if (filterData.length === 0) {
                dest.push(ai);
            }
        }
    }
    return dest;
 }

 deleteEnvironment = async (environmentId) => {
  if(window.confirm("Are you sure you want to delete this environment")) {
    let existingEnvironment = (await context.documentStore.findById(environmentId) || []).filter(item => {
        return item.type == "Environment"
    })[0]
    let self = this
    context.documentStore.delete(existingEnvironment).then(function (environmentObject) {  
      alert("Environment deleted.")      
      self.fetchData()
    }, function (err) {
        console.warn(err, err.stack)
    });
  }
 }


  render() {
    // Check if environments are loading ...
    if (this.state.loading) {
      return (
        <CircularProgress className="circular" />
      );
    }
    let itemLength

    if (this.props.match.url === "/" && this.props.match.path === "/" && this.props.location.search === "") {
      itemLength = 4
    } else {
      itemLength = this.state.items.length
    }

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1, fontSize: 24 }}>
                <div>{this.getTitle()}</div>
                <Button
                  style={{ marginTop: 20, marginBottom: 20, width: 200 }}
                  variant="outlined"
                  color="primary"
                  href = "#EditEnvironment"
                >
                  {" "}
                  Add New Environment
                </Button>
            </div>
        </div>
        <div style={{ flex: 1 }}>
          {this.state.items.slice(0, itemLength).map(item => {
            return <EnvironmentItem key={item.id} item={item} onDelete={this.deleteEnvironment} />;
          })}
        </div>
      </div >
    );
  }
}

export default withRouter(EnvironmentList);
