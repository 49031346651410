import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { UserContext } from "../../UserContext";

import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import FormControl from '@material-ui/core/FormControl';

class RemovePackage extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);

        this.state = {
            itemId : null,
            packageUuid : null,
            type: null,
            item : null,
            package : null,
            loading : true,
            redirectToType : false
        }
    }

    componentDidMount() {
        let { itemId, packageUuid, type } = this.props.match.params

        this.setState({
            redirectToType : false,
            itemId : itemId,
            packageUuid : packageUuid,
            type: type
        })

        this.loadItemAndPackage(itemId, packageUuid, type)
    }

    async loadItemAndPackage(itemId, packageUuid, type) {
        const documentStore = this.context.documentStore
        const item = await documentStore.read(type, itemId)
        const pkg = (item.packages || []).filter(p => p.uuid === packageUuid).pop()

        if (pkg) {
            this.setState({ item : item, package : pkg })
        } else {
            this.setState({ item : item, redirectToType : itemId })
        }

        return [item, pkg]
    }

    async removePackage(itemId, packageUuid, type) {
        const documentStore = this.context.documentStore
        let [item] = await this.loadItemAndPackage(itemId, packageUuid, type)

        item.packages = (item.packages || []).filter(p => p.uuid !== packageUuid)
        await documentStore.update(item)

        this.setState({ redirectToType : itemId })
    }

    render() {
        if (!this.state.item) {
            return <CircularProgress className="circular" />;
        }

        if (this.state.redirectToType) {
            return <Redirect to={"/" + this.state.type + "/" + this.state.redirectToType} />
        }

        return <Container>
            <Typography variant="h1" component="h1">{this.state.item.name}</Typography>
            <Typography variant="body1">Remove <span>{(this.state.package.threeRadical || this.state.package)['name']}</span></Typography>
            <Box>
                <FormControl>
                    <Button variant="contained" color="secondary" onClick={this.removePackage.bind(this, this.state.itemId, this.state.packageUuid, this.state.type)}>Remove</Button>
                </FormControl>
            </Box>
        </Container>
    }
}

export default withRouter(RemovePackage);
