import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Item from "./Items";
import { UserContext } from "../UserContext";
import { docToItem } from "../lib/package.js";
import hmac from "hmac-signature";
import config from "../config";
import ReactMarkdown from "react-markdown";
import Axios from "axios";

let context;

class ConnectedPackageDetails extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.isCompMounted = false;

    this.state = {
      relatedPackages: [],
      quantity: 1,
      item: null,
      loading: false,
      brand: false,
      packageSet: false,
      markdownData: "",
      isNew: true
    };
  }

  // Get package
  async fetchProductUsingID(id) {
    context = this.context;
    this.setState({loading: true});
    let packageDoc = await context.documentStore.read("Package",id);

    if(!packageDoc) {
      this.setState({
        loading: false,
        error: true,
        message: id + ' not found'
      });
    } else {
      if(id.endsWith(`-${packageDoc.package.version}`)) {
        this.setState({specificVersion: true});
        id = id.substr(0, id.length - (packageDoc.package.version.length + 1));
      } else {
        this.setState({specificVersion: false});
      }
      let versionId = id + "-" + packageDoc.package.version;
      let versionDoc = await context.documentStore.read("Version", versionId);
      let otherVersions = await context.documentStore.getLinkedObjects(packageDoc, 'Version');
      if(!otherVersions.length) {
        otherVersions = await context.documentStore.findTermInList('Version', 'parentid', [packageDoc.id]);
      }
  
      otherVersions = otherVersions.filter((versionDoc) => {
        return versionDoc.package && versionDoc.id !== versionId;
      });
  
      if(this.isCompMounted) {
        this.setState({
          loading: false,
          quantity: 1,
          item: docToItem(packageDoc),
          packageUrl: versionDoc.packageUrl,
          relatedPackages: otherVersions.map(docToItem)
        }, () => {
          this.checkPackageInfo();
        });
      }

      return packageDoc;
    }
  }

  async setItem(item, type) {
    const documentStore = this.context.documentStore
    let fullItem
    try {
      fullItem = await documentStore.read(type, item.id)
      
      if(fullItem.packages.filter(pkg => { return pkg.id === this.props.match.params.id }).length > 0){
        this.setState({ isNew: false })
      }
    } catch(e){
      console.warn("Error while retrieving " + type + " document from store: ", e)
    }
    
    let result = {}
    if(type === "Brand") {
      this.setState({ brand: fullItem ? fullItem : ( item ? item : false ) });
    }
    
    if(type === "PackageSet") {
      this.setState({ packageSet: fullItem ? fullItem : ( item ? item : false ) });
    }
  }

  componentDidUpdate(prevProps) {
    // If ID of package changed in URL, refetch PackageDetails
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchProductUsingID(this.props.match.params.id);
    }
  }

  componentDidMount() {
    this.isCompMounted = true;
    this.fetchProductUsingID(this.props.match.params.id);
    
    this.setState({ redirectAddPackage: false });

    if (this.context.selectedBrand) {
      this.setItem(this.context.selectedBrand, "Brand");
    }
    
    if (this.context.selectedPackageSet) {
      this.setItem(this.context.selectedPackageSet, "PackageSet");
    }
  }

  componentWillUnmount() {
    this.setState({ redirectAddPackage: false });
    this.isCompMounted = false;
  }

  checkPackageInfo = async () => {
    // let that = this;
    // var promise = new JSZip.external.Promise(function (resolve, reject) {
    //   JSZipUtils.getBinaryContent(that.state.packageUrl,
    //     function (err, data) {
    //       if (err) {
    //         reject(err);
    //       } else {
    //         resolve(data);
    //       }
    //     }
    //     );
    //   });

    // promise
    //   .then(JSZip.loadAsync)
    //   .then(function (zip) {
    //     if (zip?.files) {
    //       return zip?.file("threeRadical/public/README.md")?.async("string");
    //     }
    //     return zip;
    //   })
    // .then((data) => this.setState({ markdownData: data }));

    let splittedName = this.state.packageUrl.split("/");
    let zipName = splittedName[splittedName.length - 1]?.split(".")[0]

    const resp = await Axios.get(
      `${config.apiAddress}/_/gtp//external-package/0/${zipName}/public/README.md`
    );
    if (resp && resp.status === 200) {
      this.setState({ markdownData: resp.data });
    }
  };

  clickAddPackage(itemId, type) {
    this.setState({ redirectAddPackage: itemId, redirectAddPackageType: type });
  }

  playDemoGame = async () => {
    if (!config.packageDemo) {
      alert("no config.packageDemo");
      return false;
    }

    let packageName = this.convertToCamelStyle(this.state.item.name);
    let gameDemoConfig =
      config.packageDemo[packageName] || config.packageDemo.__default;
    if (!gameDemoConfig) {
      alert("config.packageDemo." + packageName + " is not found");
      return false;
    }

    let staUrl = config.staAddress;

    if (config.hmacConfig && config.hmacConfig.enableHmac) {
      let source = {};
      config.hmacConfig.fields.forEach((field, index) => {
        field = field.trim();
        if (!field) {
          return;
        }

        var fieldValue = gameDemoConfig.fieldsValue[index];
        source[field] = fieldValue;
      });

      let sign = await hmac.sign(config.hmacConfig, source);
      let hmacParams =
        "?" +
        (config.hmacConfig.signatureParameter || "signature") +
        "=" +
        sign.signature +
        "&" +
        (config.hmacConfig.nonceParameter || "nonce") +
        "=" +
        sign.nonce;

      staUrl +=
        hmacParams +
        "&externalId=" +
        (gameDemoConfig.externalId || this.context.me.email) +
        "&displayTactic=" +
        gameDemoConfig.tacticId;
    } else {
      staUrl +=
        "?email=" +
        this.context.me.email +
        "&displayTactic=" +
        gameDemoConfig.tacticId;
    }

    let array = this.state.packageUrl.split(".zip")[0].split("/");
    if (array.length > 1) {
      let packageId = array[array.length - 1];
      staUrl += "&package=" + packageId;
    }
    
    staUrl += "&schemeId=" + (config.schemeId || 0)

    window.open(staUrl);
  };

  convertToCamelStyle = (name) => {
    let camelName = "";
    let array;
    if (name.indexOf("-") >= 0) {
      array = name.split("-");
    } else {
      array = name.split(" ");
    }

    array.map((e, index) => {
      if (index === 0) {
        camelName += e.toLowerCase();
      } else {
        camelName +=
          e.substr(0, 1).toUpperCase() +
          e.substr(1, e.length - 1).toLowerCase();
      }
      return e
    });
    return camelName;
  };

  render() {
    if (this.state.loading) {
      return <div id="packageLoading">
          <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
      </div>;
    }

    if (this.state.error) {
      return <div id="packageError" style={{ margin: "1rem", display: "flex" }}>
        {this.state.message}
      </div>
    }

    if (!this.state.item) {
      return null;
    }

    if (this.state.redirectAddPackage) {
      return (
        <Redirect
          to={
            "/" + this.state.redirectAddPackageType + "/" +
            this.state.redirectAddPackage +
            "/addPackage/" +
            this.state.item.id
          }
        />
      );
    }

    return (
      <div style={{ padding: 10 }}>
        <div
          style={{
            marginBottom: 20,
            marginTop: 10,
            color: "gray",
            fontSize: 20,
          }}
        >
          {this.state.item.name}
        </div>
        <div style={{ display: "flex" }}>
          <img
            src={this.state.item.imageUrl}
            alt=""
            width={250}
            height={250}
            style={{ borderRadius: "5%", objectFit: "cover" }}
          />
          <div
            style={{
              flex: 1,
              marginLeft: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 18, marginTop: 10 }}>
              Version: {this.state.item.version}
            </div>
            <Button
              style={{ marginTop: 20, marginBottom: 20, width: 100 }}
              variant="outlined"
              color="primary"
              href={this.state.packageUrl}
            >
              {" "}
              Download
            </Button>

            <Button
              style={{ marginTop: 20, marginBottom: 20, width: 100 }}
              variant="outlined"
              color="primary"
              onClick={() => {
                this.playDemoGame();
              }}
            >
              {" "}
              Demo
            </Button>

            {this.state.brand ? (
              <Button
                style={{ marginTop: 20, marginBottom: 20, width: 150 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.clickAddPackage(this.state.brand.id, "Brand");
                }}
              >
                {" "}
                { this.state.isNew ? "Add to Brand" : "Reinstall on Brand" } {this.state.brand.name}
              </Button>
            ) : (
              ""
            )}

            {this.state.packageSet ? (
              <Button
                style={{ marginTop: 20, marginBottom: 20, width: 150 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.clickAddPackage(this.state.packageSet.id, "PackageSet");
                }}
              >
                {" "}
                { this.state.isNew ? "Add to Set" : "Reinstall on Set" } {this.state.packageSet.name}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Package description */}
        <div
          style={{
            marginTop: 30,
            marginBottom: 20,
            color: "gray",
            fontSize: 20,
          }}
        >
          Package Description
        </div>
        <div
          style={{
            marginLeft: 5,
            maxHeight: 200,
            fontSize: 13,
            overflow: "auto",
          }}
        >
          {this.state.item.description
            ? this.state.item.description
            : "This is a CHI version package"}
        </div>
        {/* Relatedpackages */}
        <div
          style={{
            marginTop: 30,
            marginBottom: 10,
            color: "gray",
            fontSize: 20,
          }}
        >
          {(this.state.specificVersion ? 'Other Versions' : 'Previous Versions')}
        </div>
        {(this.state.relatedPackages.length ? '' : 'No other versions available')}
        {(window.relatedPackages=this.state).relatedPackages.map((x) => {
          return <Item key={x.id} item={x} />;
        })}
        {this.state.markdownData && (
          <>
            <div
              style={{
                marginTop: 30,
                marginBottom: 10,
                color: "gray",
                fontSize: 20,
              }}
            >
              README
            </div>
            <ReactMarkdown>{this.state.markdownData}</ReactMarkdown>
          </>
        )}
      </div>
    );
  }
}

let PackageDetails = connect()(ConnectedPackageDetails);
export default withRouter(PackageDetails);
