import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from "query-string";
import config from "../config";
import Item from "./Items";
import {withRouter} from "react-router-dom";
import {UserContext} from "../UserContext";
import {CSVLink} from "react-csv";
import Button from '@material-ui/core/Button';

let context;
class PackageListExport extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      items: [],
      hovering: "",
    };
  }

  async fetchData() {
    this.setState({loading: true});
    context = this.context;
    let qsAsObject = queryString.parse(this.props.location.search);

    // Make the request to get items

    let query = {
      type: "Package",
      q: qsAsObject.term ? qsAsObject.term : "*"
    };

    if(!qsAsObject.term) {
      query.sort = "updated";
      query.desc = true;
      query.limit = 9999;
    }

    let results = context.documentStore
      .getItems(await context.documentStore.find("", query))
      .map((pkgRes) => {
        let threeRadical = pkgRes.threeRadical || {};
        let pkg = pkgRes.package || {};
        return {
          id: pkgRes.id,
          name: threeRadical.name || pkgRes.id,
          imageUrl: (threeRadical.icon || "").startsWith("http")
            ? pkgRes.threeRadical.icon
            : config.defaultIcon,
          version: pkg.version,
          ...threeRadical,
        };
      });

    await Promise.all(results.map(async (item, index) => {
      let data = (await context.documentStore.findById(item.version ? (item.id + '-' + item.version) : item.id));
      if(data && (data = data[0]) && data.packageUrl) {
        item.download = data.packageUrl;
      }
    }));

    this.setState({
      items: results,
      loading: false
    });

    return results;
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    // Check if packagess are loading ...
    if(this.state.loading) {
      return <CircularProgress className="circular" />;
    }
    
    let headers = [
      {label: "Name",     key: "name"},
      {label: "Version",  key: "version"},
      {label: "ID",       key: "id"},
      {label: "ZIP",      key: "download"}
    ];

    let currTime = new Date().toISOString();
    let exportFileName = 'export-'+currTime+'.csv';

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1, fontSize: 24, 'line-height': 50 }}>
            <div>Export Tactics</div>
            <div style={{ position: "absolute", right: '50%', top: 5 }}>
                <Button variant="primary">
                    <CSVLink data={this.state.items} filename={exportFileName} className="btn btn-primary" headers={headers}> Download CSV</CSVLink>
                </Button>
            </div>
          </div>
        </div>
        <div style={{padding: 20, alignItems: "center"}}>
             <table class="center">
                <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Name</th>
                      <th>Version</th>
                      <th>Id</th>
                      <th>Download</th>
                    </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, index) =>
                    <tr style={{backgroundColor: index % 2 ? '' : 'rgb(40, 40, 40)'}}>
                        <td>{index+1}</td>
                        <td key={item.id}><span>{item.name}</span></td>
                        <td>{item.version}</td>
                        <td>{item.id}</td>
                        {item.download &&  <td><a href={item.download}>ZIP ({1 + index}){item.version && ' - ' + item.version}</a></td>}
                        {!item.download && <td>Unavailable ({1 + index}){item.version && ' - ' + item.version}</td>}
                    </tr>
                  )}
              </tbody>
            </table>
        </div>
      </div>
    );
  }
}

export default withRouter(PackageListExport);