import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import {Button, IconButton, CardActions } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'

class EnvironmentItem extends Component {

  delete = () => {
    this.props.onDelete(this.props.item.id)
  }

  render() {
    return (
      <Card
        style={{ width: 200, height: 170, margin: 10, display: "inline-block" }}
      >
        <CardActionArea
          onClick={() => {
            this.props.history.push("/editenvironment/" + this.props.item.id);
          }}
        >
          <CardContent style={{ height: 50 }}>
            <div
              style={{
                marginLeft: 5,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {this.props.item.id}
            </div>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Tooltip title="Delete Environment" placement="top">
            <IconButton
            aria-label="delete"
            onClick={() => this.delete()}
            >               
              <DeleteIcon />                         
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  }
}
export default withRouter(EnvironmentItem);
