
import _ from "lodash"
export const getBrandCodes = name => {
    name = name.trim()

    return {
        brandLabel : name,
        brandName : name.replace(/\W+/g,""),
        brandUrlToken : _.snakeCase(name.toLowerCase()).replace(/_/g,"-")
    }
}