import { Box, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import React, { useContext, useState } from 'react'

import config from '../../config'
import { UserContext } from '../../UserContext'
import { PackageCardList } from './PackageCardList'

import './packageSelector.css'

const alertTypes = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success'
}
const initialAlertState = { isOpen: false, message: '', type: alertTypes.info }
// TODO: Extract alert logic to be useable from multiple components

export const PackageSelector = ({ selectedPackages, brandId }) => {
    const [filter, setFilter] = useState('')
    const [packages, setPackages] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [alert, setAlert] = useState(initialAlertState)
    
    const { documentStore } = useContext(UserContext)

    const fetchPackages = async () => {
        setIsLoading(true)

        try {
            let query = {
                type: 'Package',
                q: filter || '*'
            }

            const fetchedPackages = documentStore.getItems(await documentStore.find('', query))

            const newPackages = fetchedPackages.map(function (pkgRes) {
                let threeRadical = pkgRes.threeRadical || {}
                let pkg = pkgRes.package || {}

                return {
                    id: pkgRes.id,
                    name: pkg.name,
                    threeRadical,
                    imageUrl: (threeRadical.icon || '').startsWith('http') ? threeRadical.icon : config.defaultIcon,
                    version: pkg.version
                }
            })

            setPackages(newPackages)
        } catch (error) {
            showAlert('Error loading packages.', alertTypes.error)
            console.error('Error when fetching packages: ', error)
        }

        setIsLoading(false)
    }

    const handleSearch = (e) => {
        e.preventDefault()

        fetchPackages()
    }

    const showAlert = (message, type) => {
        setAlert({ ...alert, isOpen: true, type, message })
    }

    const closeAlert = () => {
        setAlert(initialAlertState)
    }

    return (
        <Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alert.isOpen}
                autoHideDuration={6000}
                onClose={closeAlert}
            >
                <MuiAlert onClose={closeAlert} severity={alert.type}>
                    {alert.message}
                </MuiAlert>
            </Snackbar>

            <Box>
                <Typography variant="h6">Selected Packages</Typography>

                <PackageCardList packages={selectedPackages} brandId={brandId} />
            </Box>
            <Box component="form" display="flex" onSubmit={handleSearch}>
                <TextField
                    label="Filter keyword"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    style={{ marginRight: '20px', minWidth: '400px' }}
                />

                <Button variant="outlined" color="primary" type="submit">
                    Search
                </Button>
            </Box>

            <Box marginTop="20px">
                {isLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box color="text.secondary" fontWeight="fontWeightBold" textAlign="center">
                            {packages.length ? (
                                <Typography variant="h6">Packages</Typography>
                            ) : (
                                'Click search to view packages'
                            )}
                        </Box>

                        <PackageCardList packages={packages} showAdd />
                    </>
                )}
            </Box>
        </Box>
    )
}
