import React, { Component } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Container from '@material-ui/core/Container'
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Box, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from "react-router-dom";

import { withRouter, Redirect } from "react-router-dom";
import VocoInterface from "app-context/voco-interface"
import { UserContext } from "../../UserContext"
import config from "../../config";
import { getBrandCodes } from "../../lib/brand"
import { validateDependencies } from "../../lib/package"
import ConfirmationBox from "../../components/ConfirmationBox/ConfirmationBox";
import { v4 } from "uuid"
import _ from "lodash"

class AddPackage extends Component {
    static contextType = UserContext
    
    constructor(props) {
        super(props);

        this.state = {
            itemId : null,
            item : false,
            package : false,
            pendingItems : [],
            loading: true,
            installing: false,
            isNew : true,
            redirectToType : false,
            redirectToPackage : false,
            itemDescription : ""
        }
        
        this.handleChoice = this.handleChoice.bind(this)
        
        this.formatTerm = function (dep) {
            let nameConditions = "", providesConditions = ""
            dep = dep.replace(/[@^?].*$/, "").split(/[-\/]/g).filter(s => s.length)
            
            for(let i = 0; i < dep.length; i++){
                if(i > 0){
                    nameConditions += " AND "
                    providesConditions += " AND "
                }
                nameConditions += ("properties.threeRadical.name:" + dep[i])
                providesConditions += ("properties.threeRadical.provides:" + dep[i])
            }
            
            nameConditions = "(" + nameConditions + ")"
            providesConditions = "(" + providesConditions + ")"
            
            return nameConditions + " OR " + providesConditions
        }
    }

    async handleChoice(choice) {
        if(choice === "agree"){
            await this.setState({installing: true})
            await this.addPackage()    
        }
    };
        
    handleInputChange(e) {
        let { value, name } = e.target
        this.setState({ [name] : value })
    }

    async componentDidMount() {
        let {itemId, type, packageId} = this.props.match.params
        
        this.setState({
            redirectToType : false,
            redirectToPackage : false,
            itemId : itemId,
            type: type,
            packageId : packageId
        })

        await this.loadItem(itemId, type)
        this.loadPackage(packageId)
    }

    async loadItem(itemId, type) {
        const documentStore = this.context.documentStore

        if (itemId && itemId !== "0") {
            const item = await documentStore.read(type, itemId)
            
            const results = (await documentStore.find("", { type : type, q : "Pending" })).items
            
            this.setState({
                item : item,
                type: type,
                pendingItems : results
            })
            
            if(type === "Brand") {
                const environment = await documentStore.read("Environment", item.environment)
                let deploymentInfo = environment.environment.deploymentInfo
            
                Object.keys(deploymentInfo.services).forEach(function (service) {
                    if (!deploymentInfo.services[service].secret && typeof deploymentInfo.services[service] == "object") {
                        deploymentInfo.services[service].secret = deploymentInfo.services.secret
                    }
                })
        
                this.voco = new VocoInterface(deploymentInfo)
                this.setState({
                    environment : environment
                })
                
            }
        }

    }

    async addPackage() {
        const documentStore = this.context.documentStore
        const voco = this.voco
        let { itemId, packageId } = this.state

        let [item, pkg] = await Promise.all([documentStore.read(this.state.type, itemId), documentStore.read("Package", packageId)])
        let pkgVersion, pkgUrl, pkgInstall, pkgHash
        
        if (!item.packages) {
            item.packages = []
        }
        
        let dependencies = _.get(pkg, "threeRadical.depends", [])
        
        if(!Array.isArray(dependencies)){
            throw new Error("Invalid format - threeRadical.depends should be an array")
        }
        
        if(dependencies.length > 0) {
            let pager = new this.context.documentPager(1, "updated", true, 9999)
            let packages = await this.context.documentStore.findQuery("Package", "*", pager)
            packages = (packages || []).map(p => {
                return {
                    id: p.id,
                    version: _.get(p, "package.version"),
                    provides: _.get(p, "threeRadical.provides", [])
                }
            })
            
            let missing = await validateDependencies(pkg, packages)
            
            if(!missing.ok){
                console.warn("The following package dependencies are missing from VTS. Please ensure the corresponding packages have been uploaded first. Missing dependencies: " + missing.missing.join(", "))
                return this.setState({installing: false, error : true, missingVTSDependencies: missing.missing})
            }
            
            packages = (this.state.item.packages || []).map(p => {
                return {
                    id: p.id,
                    version: p.version,
                    provides: _.get(p, "threeRadical.provides", [])
                }
            })
            
            missing = await validateDependencies(pkg, packages)
            
            if(!missing.ok){
                console.warn("The following package dependencies are missing from the " + this.state.type + " settings. Please ensure the corresponding packages have been added first. Missing dependencies: " + missing.missing.join(", "))
                return this.setState({installing: false, error : true, missingItemDependencies: missing.missing})
            }
        }
        
        let itemPackage = {
            uuid : v4(),
            id : pkg.id,
            name : pkg.name,
            version : _.get(pkg, "package.version"),
            threeRadical : {
               name : pkg.threeRadical.name,
               icon : pkg.threeRadical.icon,
               depends: pkg.threeRadical.depends || [],
               provides: pkg.threeRadical.provides || []
            },
            imageUrl: (pkg.threeRadical.icon || '').startsWith('http') ? pkg.threeRadical.icon : config.defaultIcon
        }
        
        itemPackage[this.state.type==="Brand" ? "brand" : "packageSet"] = {
            description : this.state.itemDescription || ""
        }
        
        //Avoids duplicates if reinstalling package on Completed brand
        item.packages = item.packages.filter(p => { return p.id !== packageId})
        item.packages.push(itemPackage)
        
        if(item.state === "Completed" && this.state.type === "Brand") {
            console.log("Brand in completed state, installing directly")
            let schemeId, brandCodes
            
            brandCodes = getBrandCodes(item.name)

            try {
                const schemeResults = await voco.gtp.post("/private/scheme/fromName", { appNames : [brandCodes.brandUrlToken] })
                const schemeSettings = schemeResults[brandCodes.brandUrlToken]
    
                if (!schemeSettings) {
                    throw new Error("Unable to retrieve scheme settings")
                }
    
                schemeId = _.get(schemeSettings, "webapp.globals.schemeId")
    
                if (!schemeId) {
                    throw new Error("No Scheme Id")
                }
    
            } catch (e) {
                console.warn(e, e.stack)
                return this.setState({error : true, errorMessage : e.message})
            }
            
            pkgVersion = await documentStore.read("Version", pkg.id + "-" + pkg.package.version)
            pkgUrl = pkgVersion.packageUrl

            pkgHash = pkgUrl.split("/").pop().replace(/\.zip$/,"")
            try {
                pkgInstall = await voco.gtp.post(
                    "/private/external-package/" + schemeId + "/" + pkgHash + "/processUpload/name,version,main",
                    {
                        "pathToProperties" : "schemeProperties",
                        "delimiter" : ",",
                        "packageUrl" : pkgUrl,
                        isRemotePackage: true
                    }
                )
                
            } catch (e){
                console.warn(e.error)
                if(e.error && e.error.body && Array.isArray(e.error.body)){
                    console.warn("The following package dependencies are missing from the Brand settings. Please ensure the corresponding packages have been added first. Missing dependencies: " + e.error.body.join(", "))
                    return this.setState({installing: false, error : true, missingItemDependencies: e.error.body})
                }
            }
        }

        await documentStore.update(item)
        
        await this.setState({
            installing: false,
            redirectToType: item.id
        })
    }

    async loadPackage(packageId) {
        const documentStore = this.context.documentStore
        
        if (packageId) {
            const pkg = await documentStore.read("Package", packageId)
            this.setState({ package : pkg })
            
            if(this.state.item) {
                if(this.state.item.packages.filter(pkg => { return pkg.id === packageId }).length > 0){
                    console.warn("Package already installed")
                    this.setState({isNew: false})    
                }
            }
        }
    }

    render() {
        if (this.state.redirectToType) {
            return <Redirect to={"/" + this.state.type + "/" + this.state.redirectToType} />;
        }

        if (this.state.redirectToPackage) {
            return <Redirect to={"/PackageDetails/" + this.state.redirectToPackage} />;
        }
        
        if (this.state.error) {
            return <Container>
                <Container>
                    <Box my={6}>
                        <Typography variant="h3">Package Installation Failure</Typography> 
                    </Box>
                    <Box my={3}>
                        <Typography variant="h4">{this.state.package.threeRadical.name}</Typography>
                    </Box>
                    {
                        this.state.missingVTSDependencies && (
                            <Box my={3}>
                                <Typography variant="h5"> <FiberManualRecordIcon  style={{ fontSize: 15 }}/> The following dependencies needed for this package are missing from VTS:</Typography>
                                <List className={"missingVTSDependencies"} dense={false}>
                                    { 
                                        this.state.missingVTSDependencies.map(d => (
                                            <ListItem key={d} component={Link} to={"/PackageList?term="+encodeURIComponent(this.formatTerm(d))} /*target="_blank"*/>
                                              <ListItemText primary={d} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                        )
                    }
                    {
                        this.state.missingItemDependencies && (
                            <Box my={3}>
                                <Typography variant="h5"> <FiberManualRecordIcon  style={{ fontSize: 15 }}/> The following dependencies need to be installed on the {this.state.type} before this package can be installed:</Typography>
                                <List className={"missingItemDependencies"} dense={false}>
                                    { 
                                        this.state.missingItemDependencies.map(d => (
                                            <ListItem key={d} component={Link} to={"/PackageList?term="+encodeURIComponent(this.formatTerm(d))} /*target="_blank"*/>
                                              <ListItemText primary={d} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </Box>
                        )
                    }
                </Container>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => {
                        this.setState({error: false, missingItemDependencies: null, missingVTSDependencies: null})
                    }}>
                    { "Return to Package" }
                </Button>
            </Container> ;
        }

        if (!this.state.package || this.state.installing) {
            return <div id="packageLoading">
                <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
            </div>;
        }

        return <Container>
            <Container>
                <Box my={6}>
                    <Typography variant="h3">Add Package to {this.state.type}</Typography> 
                </Box>
                <Box my={3}>
                    <Typography variant="h4">{this.state.package.threeRadical.name}</Typography>
                    <FormControl>
                        <Typography variant="body1">How will the package be used?</Typography>
                        <TextField
                            label="Description"
                            name="itemDescription"
                            value={this.state.itemDescription}
                            onChange={this.handleInputChange.bind(this)}
                        />
                    </FormControl>
                </Box>
                <Box my={3}>
                    <FormControl>
                        <InputLabel id="item-add-package-item">{this.state.type}:</InputLabel>
                        <Select labelId="item-add-package-item" value={this.state.itemId}>
                            <MenuItem value={this.state.itemId}>{this.state.item.name}</MenuItem>
                            { this.state.pendingItems.filter((function (b) { return b.id !== this.state.item.id }).bind(this)).map(b => <MenuItem value={b.id}>{b.name}</MenuItem>) }
                        </Select>
                    </FormControl>
                </Box>
                <Box my={3}>
                    <FormControl>
                        {
                            (this.state.isNew || this.state.item.state !== "Completed") ? 
                                (
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={() => {
                                            this.setState({installing: true})
                                            this.addPackage.call(this)
                                        }}>
                                        { this.state.isNew ? "Create New" : "Replace" }
                                    </Button>
                                ) : 
                                (
                                    <ConfirmationBox
                                        ButtonContent={"Reinstall On " + this.state.type}
                                        title="Reinstall Package?" 
                                        message="Reinstalling this package will overwrite all previous configuration. Proceed?" 
                                        handler={this.handleChoice} 
                                    />
                                )
                        }
                    </FormControl>
                </Box>
            </Container>
        </Container> 
    }
}

export default withRouter(AddPackage);
