import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { waitForMe } from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/Store";

let app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

waitForMe
    .then(function (me) {
        console.log("User:", me)
    }).catch(function (err) {
        return false
    })
    .then(function () {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        ReactDOM.render(app, document.getElementById("root"));
    })
