import React from 'react';
import { Redirect } from "react-router-dom";
import "./App.css";
import Index from './routes/Index'
import Menu from './routes/Menu';
import Login from './routes/Login'
import Upload from './routes/Upload'
import Brand from './routes/Brand'
import BrandList from './routes/Brand/List'
import BrandEdit from './routes/Brand/Edit'
import AddPackage from './routes/Packages/AddPackage'
import AddPackageSet from './routes/Packages/AddPackageSet'
import RemovePackage from './routes/Packages/RemovePackage'
import BrandComplete from './routes/Brand/Complete'
import PackageSetList from './routes/PackageSet/List'
import PackageSetEdit from './routes/PackageSet/Edit'
import PackageSet from './routes/PackageSet/PackageSet'
import PackageDetails from './routes/PackageDetails'
import Search from './routes/Search.js'
import PackageList from "./routes/PackageList"
import EnvironmentList from "./routes/EnvironmentList"
import Template from "./routes/Template"
import Categories from "./routes/Categories"
import EditEnvironment from "./routes/EditEnvironment"
import {UserContext} from './UserContext'
import {ParaClient, Pager} from "para-client-js/lib"
import config from './config'
import ExportTacticsList from "./routes/ExportTacticsList"

import {
    HashRouter as Router,
    Switch,
    Route
  } from "react-router-dom"

let pc = null
let me = null
let waitForMe = null
let jwt = null
let urlParams = new URLSearchParams(window.location.search)

jwt = urlParams.get('jwt')
if (jwt && typeof window.sessionStorage == "object") {
    window.sessionStorage.setItem("jwt", jwt)

    urlParams.delete("jwt")
    let newParams = urlParams.toString()

    if (window.history && window.history.replaceState) {
        window.history.replaceState(null, '', window.location.pathname + (newParams ? '?' + newParams : ''))
    }
} else if (typeof sessionStorage == "object" && sessionStorage.getItem("jwt")) {
    jwt = window.sessionStorage.getItem("jwt")
}


if (jwt) {
    pc = new ParaClient(config.accessKey, null, { endpoint : config.endpoint })
    pc.setAccessToken(jwt)
    waitForMe = pc.invokeGet('/_me')
        .then(function (result) {
            me = result.body

            if (me.name === "No Name") {
                me.name = ""
            }

            return me

        }).catch(function (err) {
            console.warn(err, err.stack)
            return false
        })
} else {
    waitForMe = Promise.resolve(false)
}

export default class App extends React.Component {

    constructor(props) {
        super(props)

        this.setSelectedBrand = (brand) => {
            if (brand) {
                this.setState({ selectedBrand : { id : brand.id, name : brand.name } })
            } else {
                this.setState({ selectedBrand : false })
            }
        }
        
        this.setSelectedPackageSet = (packageSet) => {
            if (packageSet) {
                this.setState({ selectedPackageSet : { id : packageSet.id, name : packageSet.name } })
            } else {
                this.setState({ selectedPackageSet : false })
            }
        }

        this.state = {
            me,
            documentStore : pc,
            selectedBrand : false,
            setSelectedBrand : this.setSelectedBrand,
            selectedPackageSet: false,
            setSelectedPackageSet : this.setSelectedPackageSet,
            documentPager : Pager
        }
    }
    
    render() {
      return (
        <UserContext.Provider value={this.state}>
            <Router>
          <div className="app">
            <Search />
            <div className="app-body">
                <Menu />
              <div className="content">
                <Switch>
                    <Route path = "/Login">
                        <Login />
                    </Route>
                    <Route path = "/Upload">
                        <Upload />
                    </Route>
                    <Route path = "/:type/:itemId/removePackage/:packageUuid">
                        { me?.id && <RemovePackage /> }
                        { !me?.id && <Redirect to={"/Login"} /> }
                    </Route>
                    <Route path = "/:type/:itemId/addPackage/:packageId">
                        { me?.id && <AddPackage /> }
                        { !me?.id && <Redirect to={"/Login"} /> }
                    </Route>
                    <Route path = "/:type/:itemId/addPackageSet/:packageSetId">
                        { me?.id && <AddPackageSet /> }
                        { !me?.id && <Redirect to={"/Login"} /> }
                    </Route>
                    <Route path = "/Brand/:id/complete">
                        <BrandComplete />
                    </Route>
                    <Route path = "/Brand/:id/edit">
                        <BrandEdit />
                    </Route>
                    <Route path = "/Brand/list">
                        <BrandList />
                    </Route>
                    <Route path = "/Brand/:id">
                        <Brand />
                    </Route>
                    <Route path = "/PackageSet/list">
                        { me?.id && <PackageSetList/> }
                        { !me?.id && <Redirect to={"/Login"} /> }
                    </Route>
                    <Route path = "/PackageSet/:id/edit">
                        { me?.id && <PackageSetEdit /> }
                        { !me?.id && <Redirect to={"/Login"} /> }
                    </Route>
                    <Route path = "/PackageSet/:id">
                        { me?.id && <PackageSet /> }
                        { !me?.id && <Redirect to={"/Login"} /> }
                    </Route>
                    <Route path = "/PackageList">
                        <PackageList />
                    </Route>
                    <Route path="/PackageDetails/:id">
                        <PackageDetails />
                    </Route>
                    <Route path = "/EnvironmentList">
                        <EnvironmentList />
                    </Route>
                    <Route path="/EditEnvironment/:id?">
                        <EditEnvironment />
                    </Route>
                    <Route path="/Template">
                        <Template />
                    </Route>
                    <Route path="/Categories/:slug?">
                        <Categories />
                    </Route>
                    <Route path = "/ExportTacticsList">
                        <ExportTacticsList />
                    </Route>
                    <Route exact path="/">
                        <Index />
                    </Route>
                </Switch>
              </div>
            </div>
          </div>
            </Router>
        </UserContext.Provider>
      );
    }
}

export { waitForMe }
