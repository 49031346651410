import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter, Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { UserContext } from "../../UserContext";
import GridItem from "../../components/presentational/GridItem";

/* Grid */
import Grid from "@material-ui/core/Grid";

class PackageSetList extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.isCompMounted = false;

    this.state = {
      packageSetsPending: [],
      packageSetsCompleted: [],
      finalized: [],
      loading: true,
      redirectToPackageSetsEdit: false,
      searchFinalized: "",
    };
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.setState({ redirectToPackageSetsEdit: false });
    this.updateList();
  }

  componentWillUnmount() {
    this.isCompMounted = false;
  }

  createNew() {
    this.setState({ redirectToPackageSetsEdit: "0" });
  }

  async updateList() {
    this.setState({ loading: true });

    const documentStore = this.context.documentStore;
    const results = (await documentStore.find("", { type: "PackageSet", q: "*" }))
      .items;
      
    const pendingCompleted = results.reduce(
      function (pc, packageSet) {
        if (packageSet.state === "Completed") {
          pc.finalized.push(packageSet);
        } else if (packageSet.state === "Finalized") {
          pc.finalized.push(packageSet);
        } else if (packageSet.state === "Pending") {
          pc.pending.push(packageSet);
        }

        return pc;
      },
      { pending: [], completed: [], finalized: [] }
    );

    this.setState({
      pending: pendingCompleted.pending,
      complete: pendingCompleted.completed,
      finalized: pendingCompleted.finalized,
      loading: false,
    });
  }

  handleSearchFinalized = (value) => {
    this.setState({
      searchFinalized: value,
    });
  };

  render() {
    const filteredFinalized = this.state.finalized.filter((data) => {
      return data.name
        .toLowerCase()
        .includes(this.state.searchFinalized.toLowerCase());
    });

    if (this.state.loading) {
      return <div id="packageLoading">
        <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
      </div>;
    }

    if (this.state.redirectToPackageSetsEdit) {
      return (
        <Redirect to={"/PackageSet/" + this.state.redirectToPackageSetsEdit + "/edit"} />
      );
    }

    const fabStyle = {
      float: "right",
    };

    return (
      <Container max-width="sm" style={{ padding: 10 }}>
        <Box my={6}>
          <Typography variant="h2" component="h2">
            Package Sets
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h4" component="h4">
              Pending
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Fab
              aria-label="add new"
              style={fabStyle}
              className="fab-add-order"
              color="primary"
              onClick={this.createNew.bind(this)}
            >
              <AddIcon />
            </Fab>
          </Grid>
          {this.state.pending.map((packageSet) => (
            <GridItem item={packageSet} key={packageSet.id} type="PackageSet"/>
          ))}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant="h4" component="h4">
              Finalized
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Search"
              value={this.state.searchFinalized}
              onChange={(e) => {
                this.handleSearchFinalized(e.target.value);
              }}
              style={{ width: "60%", marginBottom: 15 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {filteredFinalized
                .sort(function(a, b) { return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1; })
                .map((packageSet) => (
                  <GridItem item={packageSet} key={packageSet.id} type="PackageSet"/>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(PackageSetList);
