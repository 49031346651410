import configLocal from "./config.local.json"
import configDemo from "./config.demo.json"
import configPro from "./config.pro.json"

import hmacConfigLocal from "./hmacConfig.local.json"
import hmacConfigDemo from "./hmacConfig.demo.json"
import hmacConfigPro from "./hmacConfig.pro.json"

import _ from "lodash"

let config = null
let environment = process.env['NODE_ENV'] || "" 

if((configLocal.hostname && _.some(configLocal.hostname, hostname => window.location.hostname.startsWith(hostname))) || 'local' === environment || window.location.hostname === "localhost") {
    config = configLocal
    config.hmacConfig = hmacConfigLocal
} else if (environment === "development" || window.location.hostname.startsWith("demo-")) {
    config = configDemo
    config.hmacConfig = hmacConfigDemo
} else {
    config = configPro
    config.hmacConfig = hmacConfigPro
}

config.packageDemo = config.packageDemo || {}
//a video-markdown tactic, but the demo package will be loaded with the default config of the game's latest package in VTS
config.packageDemo.__default = config.packageDemo.__default || {
    "schemeId": 82,
    "tacticId": 3492
}

export default config