import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { kebabCase } from "../lib/cases";

let packageJson = (kebabCasedTitle, title) => `{
    "name": "${kebabCasedTitle}",
    "version" : "0.0.1",
    "threeRadical" : {
        "name" : "${title}"
    }
}`;

const zip = new JSZip();

const Template = () => {
  const [packageName, setPackageName] = useState("");

  const handleDownload = () => {
    zip.file("package.json", packageJson(kebabCase(packageName), packageName));
    let threeRadicalDirectory = zip.folder("threeRadical");
    threeRadicalDirectory.folder("css");
    threeRadicalDirectory.folder("unlockables");
    threeRadicalDirectory.folder("stories");
    threeRadicalDirectory.folder("public");
    threeRadicalDirectory.folder("exports");
    threeRadicalDirectory.folder("batch");
    threeRadicalDirectory.folder("services");
    threeRadicalDirectory.folder("functions");
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${kebabCase(packageName)}.zip`);
      setPackageName("");
    });
  };

  return (
    <div style={{ margin: "5%" }}>
      <TextField
        label="Name of the package"
        value={packageName}
        onChange={(e) => {
          setPackageName(e.target.value);
        }}
        onKeyPress={(event) => {
          if(event.key === 'Enter' && packageName){
           handleDownload()
          }
        }}
        style={{ width: "30%", marginBottom: 15 }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDownload}
        disabled={packageName ? false : true}
      >
        Download Template
      </Button>
    </div>
  );
};

export default Template;
