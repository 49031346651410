import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

class ConnectedItem extends Component {
  render() {
    return (
      <Card
        style={{ width: 200, height: 270, margin: 10, display: "inline-block" }}
      >
        <CardActionArea
          onClick={() => {
            this.props.history.push("/packagedetails/" + this.props.item.id);
          }}
        >
          <CardMedia style={{ height: 140 }} image={this.props.item.imageUrl} />
          <CardContent style={{ height: 50 }}>
            <div
              style={{
                marginLeft: 5,
                fontWeight: "bold",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {this.props.item.name}
            </div>
            <div style={{ margin: 5 }}>
              Version: {this.props.item.version}
            </div>
            {this.props.hovering === this.props.i  && (
              <span>
                <img src={this.props.item?.gif} alt=""/>
              </span>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}
export default withRouter(ConnectedItem);
