import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter, Redirect } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { UserContext } from "../../UserContext";
import GridItem from "../../components/presentational/GridItem";

/* Grid */
import Grid from "@material-ui/core/Grid";

class BrandList extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);

    this.isCompMounted = false;

    this.state = {
      brandsPending: [],
      brandsCompleted: [],
      finalized: [],
      loading: true,
      redirectToBrandEdit: false,
      searchFinalized: "",
    };
  }

  componentDidUpdate(prevProps) {}

  componentDidMount() {
    this.setState({ redirectToBrandEdit: false });
    this.updateList();
  }

  componentWillUnmount() {
    this.isCompMounted = false;
  }

  createNew() {
    this.setState({ redirectToBrandEdit: "0" });
  }

  async updateList() {
    this.setState({ loading: true });

    const documentStore = this.context.documentStore;
    const results = (await documentStore.find("", { type: "Brand", q: "*", limit: 9999 }))
      .items;

    const environments = documentStore.getItems((await documentStore.find("", {
        type : "Environment",
        sort : "updated",
        desc : true
      }))).map((environmentRes) => environmentRes.id)

    const pendingCompleted = results.reduce(
      function (pc, brand) {
        if (brand.state === "Completed") {
          pc.finalized.push(brand);
        } else if (brand.state === "Finalized") {
          pc.finalized.push(brand);
        } else if (brand.state === "Pending") {
          pc.pending.push(brand);
        }
        return pc;
      },
      { pending: [], completed: [], finalized: [] }
    );

    this.setState({
      pending: pendingCompleted.pending,
      complete: pendingCompleted.completed,
      finalized: pendingCompleted.finalized,
      environments: environments,
      loading: false,
    });
  }

  handleSearchFinalized = (value) => {
    this.setState({
      searchFinalized: value,
    });
  };

  render() {
    const filteredFinalized = this.state.finalized.filter((data) => {
      return data.name
        .toLowerCase()
        .includes(this.state.searchFinalized.toLowerCase());
    });

    if (this.state.loading) {
      return <div id="packageLoading">
        <CircularProgress style={{height: "18vh", width: "18vh"}} className="circular"/>
      </div>;
    }

    if (this.state.redirectToBrandEdit) {
      return (
        <Redirect to={"/Brand/" + this.state.redirectToBrandEdit + "/edit"} />
      );
    }

    const fabStyle = {
      // position:"relative",
      // width:"60px",
      // height:"60px",
      // top:"140px",
      // right:"40px",
      float: "right",
    };

    return (
      <Container max-width="sm" style={{ padding: 10 }}>
        <Box my={6}>
          <Typography variant="h1" component="h1">
            Brands
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h4" component="h4">
              Pending
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Fab
              aria-label="add new"
              style={fabStyle}
              className="fab-add-order"
              color="primary"
              onClick={this.createNew.bind(this)}
            >
              <AddIcon />
            </Fab>
          </Grid>
          {this.state.pending.map((brand) => (
            <GridItem item={brand} key={brand.id} type="Brand"/>
          ))}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant="h4" component="h4">
              Finalized
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Search"
              value={this.state.searchFinalized}
              onChange={(e) => {
                this.handleSearchFinalized(e.target.value);
              }}
              style={{ width: "60%", marginBottom: 15 }}
            />
          </Grid>
          {this.state.environments.map((env) => (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h5">
                    * {env}
                  </Typography>
                </Grid>
                {filteredFinalized
                  .filter((brand) => brand.environment === env)
                  .sort(function(a, b) { return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1; })
                  .map((brand) => (
                    <GridItem item={brand} key={brand.id} type="Brand" />
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* <Grid container spacing={3}>
        <
            <Grid item xs>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Airbus
                        </Typography>
                        <Typography color="textSecondary">
                            Description
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Spin and Win
                        </Typography>
                        <Typography color="textSecondary">
                            Description
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Kitty Bingo
                        </Typography>
                        <Typography color="textSecondary">
                            Description
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid> */}

        {/* <Grid container spacing={3}>
            <Grid item xs>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Some brand
                        </Typography>
                        <Typography color="textSecondary">
                            Description
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Some other brand
                        </Typography>
                        <Typography color="textSecondary">
                            Description
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs>
                <Card >
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Another brand
                        </Typography>
                        <Typography color="textSecondary">
                            Description
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
 */}
      </Container>
    );
  }
}

export default withRouter(BrandList);
