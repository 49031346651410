import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentIcon from '@material-ui/icons/Assignment'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { PackageCard } from './PackageCard'

export const PackageCardList = ({ packages, showAdd, brandId }) => (
    <Box display="flex" flexWrap="wrap">
        {packages.map((pkgInfo) => {
            return (
                <Box className="package-card-wrapper" key={pkgInfo.id} style={{ position: 'relative', margin: 10 }}>
                    <Box className="selector-overlay">
                        <Box display="flex" justifyContent="space-between" padding={ showAdd ? "0px 72px 2px" : "0px 32px 2px"}>
                            { !showAdd && (
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to={"/Brand/" + brandId + "/removePackage/" + pkgInfo.uuid}
                                    color="secondary"
                                    disableElevation
                                >
                                    <DeleteIcon />
                                </Button>
                            )}

                            <Button
                                component={RouterLink}
                                to={`/packagedetails/${pkgInfo.id}`}
                                //target="_blank" Deactivated for now due to session not carrying over to other tab for the time being, crashing the newly opened details page, Add action redirects to brand currently anyway
                                variant="contained"
                                color="default"
                                disableElevation
                            >
                                <AssignmentIcon />
                            </Button>
                        </Box>
                    </Box>
                    <PackageCard info={pkgInfo} />
                </Box>
            )
        })}
    </Box>
)
