import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../config";

import { UserContext } from "../../UserContext";
import Items from "../Items";

const CategoriesList = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    loading: false,
    items: [],
    filteredItems: [],
  });

  const context = useContext(UserContext);

  const fetchData = async () => {
    setState({ ...state, loading: true });
    let queryData = history.location?.pathname?.split("/").pop();
    let qsAsObject = queryData === "Categories" ? undefined : queryData;

    // Make the request to get items

    let query = {
      type: "Package",
      q: qsAsObject
        ? `properties.threeRadical.categories.${qsAsObject}:true`
        : "*",
    };

    if (!qsAsObject) {
      query.sort = "updated";
      query.desc = true;
      query.limit = 20;
    }

    let results = context.documentStore
      .getItems(await context.documentStore.find("", query))
      .map(function (pkgRes) {
        let threeRadical = pkgRes.threeRadical || {};
        let pkg = pkgRes.package || {};
        return {
          id: pkgRes.id,
          name: threeRadical.name || pkgRes.id,
          imageUrl: (threeRadical.icon || "").startsWith("http")
            ? pkgRes.threeRadical.icon
            : config.defaultIcon,
          version: pkg.version,
          ...threeRadical,
        };
      }).filter(data => data.categories);

    const cateNames = results.reduce(
      (cate, item) => {
        if (item.categories) {
          for (let title in item.categories) {
            if (item.categories[title]) {
              cate.cateTitle.push(title);
            }
          }
        }
        return cate;
      },
      { cateTitle: [] }
    );

    setState({
      ...state,
      items: results,
      loading: false,
      cateTitle: [...new Set(cateNames.cateTitle)],
      filteredItems: results,
    });

    return results;
  };

  useEffect(() => {
    fetchData();
  }, [history.location.pathname]);

  const handleClick = (categoryName) => {
    // let filteredItems = [];
    // state.items.forEach((item) => {
    //   if (
    //     item.categories &&
    //     Object.keys(item.categories).includes(categoryName)
    //   ) {
    //     filteredItems.push(item);
    //   }
    // });

    // setState({
    //   ...state,
    //   filteredItems,
    // });

    history.push("/Categories/" + categoryName);
    setState({ ...state });
    // fetchData()
  };

  if (state.loading) {
    return <CircularProgress className="circular" />;
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ padding: 10, display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1, fontSize: 24 }}>
          <div style={{ display: "flex" }}>
            {state.cateTitle?.map((item, i) => {
              return (
                <div
                  key={i}
                  style={{
                    background: "#d3c7d3",
                    padding: "1.5rem",
                    margin: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(item)}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {state.items.map((item, i) => {
          return (
            <span key={i}>
              <Items key={item.id} item={item} i={i} />
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default CategoriesList;
